import { createContext, useEffect, useState } from "react";
// const cc_ws_client = require('/Users/nitu/Circlechess/cc-client-sdk/lib/index.js');
const cc_ws_client = require('/var/www/game-service/cc-client-sdk/lib/index.js');
import Environment from '../../environment';
import {useNotificationStore} from '@/Store/useNotificationStore';

export const SocketContext = createContext(null);

interface ISocketProvider {
  children: React.ReactNode;
  username: string;
  accessToken: string;
}
// const schema = require('../../../../cc-client-sdk/lib/schema.js');
const schema = require('/var/www/game-service/cc-client-sdk/lib/schema.js');

export const SocketProvider = (props: ISocketProvider) => {
  const [gameClient, setGameClient] = useState(null);
  const {children, username, accessToken} = props;

  // Access the Zustand store
  const addNotification = useNotificationStore(state => state.addNotification);
  const setRefetchStudy = useNotificationStore(state => state.setRefetchStudy);

  useEffect(() => {
    if (username && accessToken) {
      const socket = cc_ws_client(Environment.GAME_SERVICE_URL, {
        transports: ['websocket'],
        forceNew: true,
        query: {
          username: username,
          deviceId: '92bd7016-002a-4983-ad2f-fb41b9ea45b2',
          sessionId: '6c807ca8-cfc8-4866-9ae8-f95bf66fcf2d',
          Authorization: accessToken,
        },
      });

      setGameClient(socket);
    }
  }, [username, accessToken, addNotification]);

  const notificationCallBack = (socket, data) => {
    addNotification({
      requestId: data?.requestId || Date.now().toString(),
      id: data?.requestId || Date.now().toString(),
      message: data?.message?.replace(/"/g, ''),
      userId: data?.userId,
      type: data?.type || 'info',
      timestamp: new Date().toISOString(),
    });
  };

  useEffect(() => {
    if (gameClient) {
      gameClient.registerMessageType(
        'notification',
        schema.notificationMsgSchema,
      );
      gameClient.registerMessageHandler(
        'notification',
        function (socket, data) {
          // console.log('*** startGameResponseCallback = ', data);
          notificationCallBack(socket, data);
        },
      );
      gameClient.registerMessageType(
        'alternate_move_update',
        schema.alternateStudyMove,
      );
      gameClient.registerMessageHandler(
        'alternate_move_update',
        function (socket, data) {
          debugger;
          // console.log('*** startGameResponseCallback = ', data);
          setRefetchStudy(true);
        },
      );
    }
  }, [gameClient]);

  return (
    <SocketContext.Provider value={gameClient}>{children}</SocketContext.Provider>
  )
};
