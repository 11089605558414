import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import {leaderBoardStyles} from '../../../Puzzles/styles';
import {Colors} from '@/styles';
import CCColors from '@/Utils/CCColors';
import CCAccordion from '@/Atoms/CCAccordion'; // Adjust the path as necessary
import React from 'react';
import {View, Image, ScrollView} from 'react-native';
import Spacer from '@/Atoms/Spacer';
import Tooltip from '@/Atoms/Tooltip';

const rankIcons = {
  0: require('@/Assets/Badges/1stPrize.png'),
  1: require('@/Assets/Badges/2ndPrize.png'),
  2: require('@/Assets/Badges/3rdPrize.png'),
};

const LeagueList = ({
  leagues = [],
  isMobileOrTab = false,
  selectedLeague,
  handleSelectedLeague,
  squares,
  leaderboard = [],
}) => {
  const leaderboardData = leaderboard.map(item => ({
    ...item,
    rankIcon: rankIcons[item.rank] || null,
  }));
  return (
    <View>
      <View
        style={{
          background: CCColors.Primary.Brown,
          borderRadius: 8,
          padding: 10,
        }}>
        <CCText
          fontWeight="600"
          textAlign="center"
          fontSize={16}
          color={CCColors.White}
          style={{
            lineHeight: 16,
            letterSpacing: 0.1,
            marginBottom: 20,
            marginTop: 10,
          }}>
          LEADERBOARD
        </CCText>
        <ScrollView>
          {leaderboardData.length ? (
            leaderboardData.map((item, index) => (
              <Tooltip
                text={
                  index === 0
                    ? `${item.name} is leading at the top of leaderboard with ${item.total_score} pts`
                    : `${item.name} is currently at ${
                        index === 1
                          ? `2nd`
                          : index === 2
                          ? '3rd'
                          : `${index + 1}th`
                      } position of leaderboard with ${item.total_score} pts`
                }
                children={toggleEvent => (
                  <View
                    onPointerEnter={toggleEvent}
                    onPointerLeave={toggleEvent}
                    key={index}
                    style={[
                      leaderBoardStyles.item,
                      item.name === 'You' && leaderBoardStyles.highlightedItem,
                    ]}>
                    <View style={leaderBoardStyles.rankContainer}>
                      {item.rank <= 2 && item.rankIcon ? (
                        <Image
                          source={item.rankIcon}
                          style={leaderBoardStyles.rankIcon}
                        />
                      ) : (
                        <CCText style={leaderBoardStyles.rankText}>
                          {item.rank >= 0 ? item.rank + 1 : 'N/A'}
                        </CCText>
                      )}
                    </View>
                    <View style={leaderBoardStyles.playerName}>
                      <CCText
                        numberOfLines={1}
                        style={leaderBoardStyles.playerName}>
                        {item.name ? item.name : 'N/A'}
                      </CCText>
                      <CCText
                        numberOfLines={1}
                        style={leaderBoardStyles.playerName}>
                        {item.league ? item.league : 'N/A'}
                      </CCText>
                    </View>
                    <CCText style={leaderBoardStyles.points}>
                      {item.total_score}
                    </CCText>
                  </View>
                )}
              />
            ))
          ) : (
            <CCText color={CCColors.White} style={{textAlign: 'center'}}>
              No data available.
            </CCText>
          )}
        </ScrollView>
      </View>
      <Spacer />
      <View
        style={{
          background: CCColors.White,
          borderRadius: 8,
          padding: 10,
        }}>
        <CCText
          fontWeight="600"
          textAlign="center"
          fontSize={16}
          color="#4D3F37"
          style={{
            lineHeight: 16,
            letterSpacing: 0.1,
            marginBottom: 20,
            marginTop: 10,
          }}>
          YOUR LEAGUES
        </CCText>
        {leagues.some(x => x.batch) ? (
          <CCAccordion
            sections={leagues.map(group => {
              return {
                title: group.batch,
                renderContent: () =>
                  group?.players?.map(league => (
                    <Box
                      key={league.id}
                      onPress={() => handleSelectedLeague(league)}
                      style={{
                        marginBottom: 10,
                        backgroundColor:
                          selectedLeague?.id === league.id
                            ? Colors.primaryDark
                            : CCColors.White,
                        flexDirection: 'row',
                        position: 'relative',
                        borderRadius: 8,
                        shadowColor: '#4D3F37', // Shadow color
                        shadowOffset: {
                          width: 0, // Horizontal shadow offset
                          height: 4, // Vertical shadow offset
                        },
                        shadowOpacity: 0.15, // Shadow transparency
                        shadowRadius: 8, // How blurry the shadow is
                        elevation: 4,
                      }}>
                      <Box
                        flexDirection="row"
                        justifyContent="flex-start"
                        marginHorizontal={10}
                        style={{
                          paddingVertical: 15,
                        }}
                        gap={20}>
                        <Image
                          style={{
                            height: 30,
                            width: 30,
                          }}
                          source={league.image}
                        />
                        <Box
                          flexDirection="column"
                          justifyContent="center"
                          flexWrap="wrap">
                          <CCText
                            color={
                              selectedLeague?.id === league.id
                                ? CCColors.White
                                : CCColors.Black
                            }
                            fontSize={16}
                            fontWeight="bold">
                            {league.name}
                          </CCText>
                          <CCText
                            color={
                              selectedLeague?.id === league.id
                                ? CCColors.White
                                : CCColors.Black
                            }
                            fontSize={12}>
                            {league.players_count}{' '}
                            {league.players_count > 1 ? 'members' : 'member'}
                          </CCText>
                        </Box>
                      </Box>
                      {selectedLeague?.id === league.id ? (
                        <Image
                          style={{
                            height: '100%',
                            width: 50,
                            position: 'absolute',
                            zIndex: 100,
                            right: 0,
                          }}
                          source={squares}
                        />
                      ) : null}
                    </Box>
                  )),
              };
            })}
            fontSize={isMobileOrTab ? 14 : 16}
            fontWeight="500"
            color={CCColors.Primary.lightBrown}
            paddingVertical={isMobileOrTab ? 5 : 0}
          />
        ) : isMobileOrTab ? (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {leagues.map(league => (
              <Box
                key={league.id}
                onPress={() => handleSelectedLeague(league)}
                style={{
                  marginRight: 8,
                  paddingVertical: 10,
                  backgroundColor:
                    selectedLeague?.id === league.id
                      ? Colors.primaryDark
                      : CCColors.White,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                  borderRadius: 8,
                  shadowColor: '#4D3F37', // Shadow color
                  shadowOffset: {
                    width: 0, // Horizontal shadow offset
                    height: 4, // Vertical shadow offset
                  },
                  shadowOpacity: 0.15, // Shadow transparency
                  shadowRadius: 8, // How blurry the shadow is
                  elevation: 4,
                }}>
                <Box
                  flexDirection="row"
                  marginVertical={8}
                  justifyContent="center"
                  marginHorizontal={10}
                  gap={8}>
                  <Image
                    style={{
                      height: 35,
                      width: 35,
                    }}
                    source={league.image}
                  />
                  <Box flexDirection="column" justifyContent="center">
                    <CCText
                      color={
                        selectedLeague?.id === league.id
                          ? CCColors.White
                          : CCColors.Black
                      }
                      fontSize={12}
                      fontWeight="bold">
                      {league.name}
                    </CCText>
                  </Box>
                </Box>
                {selectedLeague?.id === league.id ? (
                  <Image
                    style={{
                      height: '100%',
                      width: 50,
                      position: 'absolute',
                      zIndex: 100,
                      right: 0,
                    }}
                    source={squares}
                  />
                ) : null}
              </Box>
            ))}
          </ScrollView>
        ) : (
          leagues.map(league => (
            <Box
              key={league.id}
              onPress={() => handleSelectedLeague(league)}
              style={{
                backgroundColor:
                  selectedLeague?.id === league.id
                    ? Colors.primaryDark
                    : CCColors.White,
                flexDirection: 'row',
                position: 'relative',
                borderRadius: 8,
                shadowColor: '#4D3F37', // Shadow color
                shadowOffset: {
                  width: 0, // Horizontal shadow offset
                  height: 4, // Vertical shadow offset
                },
                shadowOpacity: 0.15, // Shadow transparency
                shadowRadius: 8, // How blurry the shadow is
                elevation: 4,
              }}>
              <Box
                flexDirection="row"
                justifyContent="flex-start"
                marginHorizontal={10}
                style={{
                  paddingVertical: 15,
                }}
                gap={20}>
                <Image
                  style={{
                    height: 30,
                    width: 30,
                  }}
                  source={league.image}
                />
                <Box
                  flexDirection="column"
                  justifyContent="center"
                  flexWrap="wrap">
                  <CCText
                    color={
                      selectedLeague?.id === league.id
                        ? CCColors.White
                        : CCColors.Black
                    }
                    fontSize={16}
                    fontWeight="bold">
                    {league.name}
                  </CCText>
                  <CCText
                    color={
                      selectedLeague?.id === league.id
                        ? CCColors.White
                        : CCColors.Black
                    }
                    fontSize={12}>
                    {league.players_count}{' '}
                    {league.players_count > 1 ? 'members' : 'member'}
                  </CCText>
                </Box>
              </Box>
              {selectedLeague?.id === league.id ? (
                <Image
                  style={{
                    height: '100%',
                    width: 50,
                    position: 'absolute',
                    zIndex: 100,
                    right: 0,
                  }}
                  source={squares}
                />
              ) : null}
            </Box>
          ))
        )}
      </View>
    </View>
  );
};

export default LeagueList;
