import Box from '@/Atoms/Box';
import {FontSizes, hs, isPhone, vs} from '@/Utils/Dimensions';

import React, {useState, useEffect} from 'react';
import {
  TextInput,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  FlatList,
} from 'react-native';
import {debounce} from 'lodash';

import CCTextInput from '@/Atoms/CCTextInput';
import CCColors from '@/Utils/CCColors';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import Divider from '@/Atoms/Divider/Divider';
import AsyncSelect from 'react-select/async';
import {GestureResponderEvent} from 'react-native';
import useLoggedInUserStore from '@/Store/loggedInUserstore';

function ChallengeCaissaComponent(props) {
  const {handleOpponentName, user} = props;

  const [searchResults, setSearchResults] = useState([
    {username: 'Caissa 800', userId: '0'},
    {username: 'Caissa 1000', userId: '0'},
    {username: 'Caissa 1200', userId: '0'},
    {username: 'Caissa 1400', userId: '0'},
    {username: 'Caissa 1600', userId: '0'},
    {username: 'Caissa 1800', userId: '0'},
    {username: 'Caissa 2000', userId: '0'},
    {username: 'Caissa 2200', userId: '0'},
    {username: 'Caissa 2400', userId: '0'},
    {username: 'Caissa 2800', userId: '0'},
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  // Debounce the searchPlayers function to delay its execution
  const debouncedSearchPlayers = debounce(
    text => handleUsernameChange(text),
    300,
  );

  const handleUsernameChange = username => {
    // Update the search term state
    setSearchTerm(username);
    // Call the debounced searchPlayers function
  };
  const handleFocus = () => {
    console.log('handleFocus Caissa challenge');
    handleUsernameChange('');
    setIsFocused(true);
  };

  const handleBlur = () => {
    // Delay setting isFocused to false by 150 milliseconds
    setTimeout(() => {
      setIsFocused(false);
    }, 300); // 150 milliseconds
  };

  const [isFocused, setIsFocused] = useState(false);

  // useEffect(() => {
  //     console.log('player list = ', searchPlayersResult);
  //     if (searchPlayersResult) {
  //         try {
  //             let parsedResult = JSON.parse(searchPlayersResult);
  //             parsedResult = Object.fromEntries(
  //                 Object.entries(parsedResult).filter(([key, value]) => value !== user)
  //             );
  //             let resultArray = Object.keys(parsedResult).map(username => ({
  //                 username: username,
  //                 userId: parsedResult[username]
  //               }))
  //             console.log('parsed list = ', parsedResult)
  //             console.log('resultArray = ', resultArray)
  //             setSearchResults(resultArray);
  //         } catch (error) {
  //             console.error('Error parsing searchPlayersResult:', error);
  //         }
  //         setIsDropdownOpen(true);
  //         setDropDownClose(false);
  //         handleOpponentName('');
  //     }
  // }, [searchPlayersResult])
  const loadOptions = debounce((inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }
    // Filter options based on input
    const filteredResults = searchResults
      .filter(player =>
        player.username.toLowerCase().includes(inputValue.toLowerCase()),
      )
      .map(player => ({
        value: player.userId + ':' + player.username,
        label: player.username,
      }));

    callback(filteredResults);
  }, 300);
  const handlePlayerSelect = selectedPlayer => {
    // Call the handleOpponentName function with the selected player's name
    setSearchTerm(selectedPlayer.username);
    handleOpponentName(selectedPlayer.userId + ':' + selectedPlayer.username);
    // Clear the search results
  };

  return (
    <View style={styles.container}>
      {/* <TouchableWithoutFeedback>
            <View>
                <TextInput
                    onChangeText={handleUsernameChange}
                    onFocus={handleFocus}
                    value={searchTerm}
                    style={styles.input}
                    placeholder="Username of the opponent"
                />
            
            
                <View>
                    {searchResults && isDropdownOpen && (
                        <View style={styles.dropdown}>
                            {Object.entries(searchResults).map(([username, userId]) => (
                                <TouchableOpacity key={userId} onPress={() => handlePlayerSelect({ username, userId })}>
                                    <Box type="row-center">
                                        <Text style={styles.dropdownItem}>{username}</Text>
                                    </Box>
                                </TouchableOpacity>
                            ))}
                        </View>
                    )}
                </View>
            </View>
            </TouchableWithoutFeedback> */}
      <Box overflow="visible" style={{zIndex: -1, width: '80%'}}>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={searchResults.map(player => ({
            value: player.userId + ':' + player.username,
            label: player.username,
          }))}
          value={
            searchTerm ? {label: searchTerm, value: searchTerm} : null // This allows the placeholder to show
          }
          onChange={selectedOption => {
            if (!selectedOption) {
              setSearchTerm(''); // Reset state when cleared
              handleOpponentName(''); // Clear opponent name
            } else {
              setSearchTerm(selectedOption.label);
              handleOpponentName(selectedOption.value);
            }
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Select Strength"
          isClearable
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor:
                searchTerm === '' ? CCColors.RedBorder : CCColors.BorderLight,
              boxShadow:
                searchTerm === '' ? CCColors.RedBorder : CCColors.BorderLight,
              borderWidth: searchTerm === '' ? 2 : 1,
            }),
            indicatorsContainer: provided => ({
              ...provided,
              boxShadow:
                searchTerm === '' ? CCColors.RedBorder : CCColors.BorderLight,
            }),
            menu: provided => ({
              ...provided,
              maxHeight: 150,
              overflowY: 'auto',
            }),
            menuList: provided => ({
              ...provided,
              maxHeight: 150,
              overflowY: 'auto',
            }),
          }}
        />
      </Box>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    alignItems: 'center',
    padding: 8,
    // width: '90%'
  },
  input: {
    backgroundColor: 'white',
    borderColor: '#22222220',

    width: isPhone ? '90vw' : '40vw',
    height: 48,
    borderRadius: 8,
    padding: 12,
    placeholderTextColor: '#26232240',
  },
  dropdown: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: 8,
    borderColor: '#22222220',
    borderWidth: 1,
    width: 480,
    zIndex: 0,
    bottom: 0,
  },
  autoCompleteFlatList: {
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    borderRadius: 10,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dropdownItem: {
    padding: 12,
  },
});

export default ChallengeCaissaComponent;
