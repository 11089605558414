// Import necessary components and styles
import React, {useState} from 'react';
import {StyleSheet, View, TextInput, Text} from 'react-native';
import Dropzone from 'react-dropzone';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import upload from '@/Assets/Images/png/upload.png';
import {WINDOW_WIDTH} from '@/Utils/Dimensions';
import useUploadScoreSheet from '@/Hooks/GetOTBGameDetails/uploadScoreSheet';
import Spacer from '@/Atoms/Spacer';

// Define the UploadBook component
const UploadScoreSheet: React.FC<any> = ({
  isUploaded,
  setIsUploaded,
  file,
  setFile,
  style,
  gameUuid,
  setImageUrl,
  postUploadCallback,
  imageUrl,
  ...rest
}) => {
  const [imgUploadingError, setImgUploadingError] = useState('');
  const [pgnText, setPgnText] = useState('');

  const {mutate: uploadScoreSheet, isLoading} = useUploadScoreSheet(
    data => {
      setImgUploadingError('');
      setImageUrl(data?.file_url);
      postUploadCallback?.();
    },
    error => {
      console.log(
        'error while uploading scoresheet',
        error?.response?.data?.error,
      );
      setIsUploaded(false);
      setImgUploadingError(error?.response?.data?.error || 'error');
    },
  );

  // Function to handle file drop
  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
    setPgnText('');
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (isLoading) return;
    if (file || pgnText.trim()) {
      uploadScoreSheet({
        game_uuid: gameUuid,
        file: file,
        pgn_content: pgnText.trim(),
      });
      setIsUploaded(true);
    }
  };

  return (
    <Box style={[styles.container, style]}>
      {(!isUploaded || !imageUrl) && (
        <Dropzone accept="*/*" onDrop={handleDrop}>
          {({getRootProps, getInputProps}) => (
            <View {...getRootProps()} style={styles.dropContainer}>
              <img src={upload} />
              <input {...getInputProps()} />
              <CCText style={{marginTop: 10}}>
                Click to select a file (Upload only image files, PDF, PNG, PGN)
              </CCText>
            </View>
          )}
        </Dropzone>
      )}
      <CCText style={{textAlign: 'center'}}>Or</CCText>
      <Spacer spacing={10} />
      <TextInput
        style={styles.textArea}
        multiline
        placeholder="Paste PGN text here"
        value={pgnText}
        onChangeText={text => {
          setPgnText(text);
          setFile(null);
        }}
      />
      {file && (
        <View>
          <CCText textAlign="center" style={{margin: 5}}>
            Selected file: {file.name}
          </CCText>
        </View>
      )}
      <CCButton
        onPress={handleSubmit}
        disabled={!file && !pgnText.trim()}
        icon={upload}
        loading={isLoading}>
        {WINDOW_WIDTH > 1000 && <CCText textAlign="center">Upload</CCText>}
      </CCButton>
      <Spacer spacing={10} />
      {(file || pgnText) && (
        <CCButton
          onPress={() => {
            setFile(null);
            setPgnText('');
          }}
          disabled={!file && !pgnText.trim()}>
          Clear
        </CCButton>
      )}
      {imgUploadingError && (
        <CCText color={CCColors.Red} textAlign="center">
          {imgUploadingError === 'error'
            ? 'Please upload a valid file or paste valid PGN text'
            : imgUploadingError}
        </CCText>
      )}
    </Box>
  );
};

// Define component styles
const styles = StyleSheet.create({
  container: {
    width: '70%',
    padding: 20,
    backgroundColor: CCColors.Primary.lightYellow,
    borderRadius: 20,
    paddingVertical: 40,
  },
  dropContainer: {
    borderWidth: 2,
    borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
    padding: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  input: {
    height: 40,
    borderWidth: 1,
    borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
    padding: 10,
    marginBottom: 20,
  },
  textArea: {
    borderWidth: 1,
    borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
    padding: 10,
    minHeight: 100,
    marginBottom: 20,
    textAlignVertical: 'top',
  },
});

export default UploadScoreSheet;
