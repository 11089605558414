import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {
  Picker,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import {Dimensions} from 'react-native';
import {FontSizes, WINDOW_HEIGHT, WINDOW_WIDTH} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';
import CCText from '@/Atoms/CCText';
import styles from './styles';
import CCColors from '@/Utils/CCColors';
import Box from '@/Atoms/Box';
import IntuitionTrainer from './components/IntuitionTrainer';
import SquareTrainer from './components/SquareTrainer';
import CalculationTrainer from './components/CalculationTrainer';
import VisualisationMemory from './components/MemoryTrainer';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import useIntuitionTrainerState from '@/Store/IntuitionTrainerStore';
import ChipList from '@/Atoms/ChipList/ChipList';
import ConditionalRender from '@/Atoms/ConditionalRender';
import Icon from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import {useRoute} from '@react-navigation/native';
import Divider from '@/Atoms/Divider/Divider';
import {
  TabView,
  TabBar,
  SceneMap,
  NavigationState,
  Route,
} from 'react-native-tab-view';
import {renderBoxTabBar, renderUnderlineTabBar} from '@/Atoms/CCTabs';
import {log} from 'console';
import {renderTabBar} from '@/Components/TabBar';
import {renderTabBarPill} from '@/Components/TabBar/TabBarPill';
import IntuitionTrainerAnalysis from './components/IntuitionTrainer/IntuitionTrainerAnalysis';

const clearLocalStorage = () => {
  localStorage.clear();
};

const ALL_VISUALISATOIN_TABS = [
  'Calculation Trainer',
  'Intuition Trainer',
  'Square Trainer',
];

const Visualisation = ({dashboardData}) => {
  const routeParams = useRoute();
  const selectedTabName = routeParams?.params?.selected;
  const trainerStore = useIntuitionTrainerState();
  const [showDropDown, setShowDropDown] = useState<boolean>(true);
  const [index, setIndex] = useState(0);
  const {isMobileOrTab} = useMediaQuery();
  const [selectedValue, setSelectedValue] = useState(
    ALL_VISUALISATOIN_TABS?.includes(trainerStore?.selectedTrainer)
      ? trainerStore?.selectedTrainer
      : 'Square Trainer',
  );
  const [color, setColor] = useState<string>('white');
  const [games, setGames] = useState<any[]>(trainerStore.games || []);
  const [level, setLevel] = useState<number>(0);
  const [pgn, setPgn] = useState<string[]>([]);
  const [item, setItem] = useState<any>(trainerStore.item || '');
  const [isGameStarted, setIsGameStarted] = useState<boolean>(
    trainerStore.isGameStarted || false,
  );

  useEffect(() => {
    setItem(trainerStore.item);
    setColor(trainerStore.playerColor);
    setPgn(trainerStore.pgn);
    setIsGameStarted(trainerStore.isGameStarted);
  }, [trainerStore.isGameStarted]);
  useEffect(() => {
    trainerStore.setIsGamesFetched(false);
  }, []);
  const [routes] = React.useState(
    [
      {key: 'Square Trainer', title: 'Square Trainer'},
      {key: 'Calculation Trainer', title: 'Calculation Trainer'},
      {key: 'Intuition Trainer', title: 'Intuition Trainer'},

      // { key: "previousTournaments", title: "Previous Tournaments" },
    ]?.filter(i => i),
  );

  const getIndex = useMemo(() => {
    const trainerMap = {
      calculation: 1,
      intuition: 2,
      square: 0,
    };

    return selectedTabIndex => {
      if (!selectedTabName) return undefined;

      const trainerName = selectedTabName.toLowerCase().split(' ')[0];
      return trainerMap[trainerName];
    };
  }, [routes]);

  useEffect(() => {
    const newIndex = getIndex(selectedTabName);
    if (newIndex !== undefined && newIndex !== index) {
      setSelectedValue(routes[newIndex].title);
      setIndex(newIndex);
    }
  }, [selectedTabName]);

  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    let returnJSX = <></>;

    switch (tempRoute.key) {
      case 'Square Trainer':
        return (
          <SquareTrainer
            selectedValue={selectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
      case 'Calculation Trainer':
        return (
          <CalculationTrainer
            selectedValue={selectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );

      case 'Visualisation memory':
        return (
          <VisualisationMemory
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
      case 'Intuition Trainer':
        return (
          <IntuitionTrainer
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,

      behavior: 'smooth',
    });
  }, [selectedValue]);

  const getMobileTrainer = useCallback(() => {
    switch (selectedValue) {
      case 'Square Trainer':
        return (
          <SquareTrainer
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
      case 'Calculation Trainer-1 move':
        return (
          <CalculationTrainer
            movesCount={1}
            selectedValue={selectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );

      case 'Calculation Trainer-2 moves':
        return (
          <CalculationTrainer
            movesCount={2}
            selectedValue={selectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );

      case 'Calculation Trainer-3 moves':
        return (
          <CalculationTrainer
            movesCount={3}
            selectedValue={selectedValue}
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );

      case 'Visualisation memory':
        return (
          <VisualisationMemory
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
      case 'Intuition Trainer':
        return (
          <IntuitionTrainer
            setShowDropDown={setShowDropDown}
            dashboardData={dashboardData}
          />
        );
    }
  }, [selectedValue]);

  return (
    <Box
      style={{
        backgroundColor: CCColors.Primary.lightBrown,
        height: selectedValue === 'Intuition Trainer' ? '95vh' : '100%',
      }}>
      {isGameStarted ? (
        <IntuitionTrainerAnalysis
          color={color}
          pgn={pgn}
          game={item}
          setIsGameStarted={setIsGameStarted}
          setLevel={setLevel}
        />
      ) : (
        <>
          {showDropDown && (
            <Box
              flexDirection={isMobileOrTab ? 'column' : 'row'}
              style={{
                paddingLeft: isMobileOrTab ? '1%' : '2%',
                marginTop: 24,
                marginBottom: 16,
                paddingRight: isMobileOrTab ? '1%' : '2%',

                ...(isMobileOrTab && {gap: 10}),
              }}>
              <ConditionalRender
                condition={isMobileOrTab}
                childrenA={
                  <CCText
                    style={[styles.label, {marginLeft: '5%', flex: 1}]}
                    fontSize={isMobileOrTab ? 20 : 24}>
                    Trainers
                  </CCText>
                }
              />

              <ConditionalRender
                condition={!isMobileOrTab && !isGameStarted}
                childrenA={
                  <TabView
                    lazy
                    // @ts-ignore
                    navigationState={{index: index, routes}}
                    renderScene={renderScene}
                    renderTabBar={props => {
                      const {navigationState} = props;
                      const {index: activeIndex, routes} = navigationState;

                      return (
                        <TabBar
                          {...props}
                          indicatorStyle={{
                            width: '33.2%',
                            height: '100%',
                            backgroundColor: CCColors.Primary.Brown,
                            borderTopLeftRadius:
                              props.navigationState.index === 0 ? 8 : 0, // Rounded for first tab
                            borderBottomLeftRadius:
                              props.navigationState.index === 0 ? 8 : 0,
                            borderTopRightRadius:
                              props.navigationState.index ===
                              props.navigationState.routes.length - 1
                                ? 8
                                : 0, // Rounded for last tab
                            borderBottomRightRadius:
                              props.navigationState.index ===
                              props.navigationState.routes.length - 1
                                ? 8
                                : 0,
                          }}
                          style={{
                            backgroundColor: CCColors?.White,
                            width: '100%',
                            borderWidth: StyleSheet.hairlineWidth,
                            borderColor: CCColors.Grey.Border,
                            padding: 0,
                            borderRadius: 8,
                          }}
                          contentContainerStyle={{
                            height: 32, // Set the height for the tablist
                          }}
                          renderLabel={({route, focused, ...rest}) => {
                            const totalItems =
                              props?.navigationState?.routes?.length;
                            const itemId =
                              props?.navigationState?.routes?.findIndex(
                                i => i?.key === route?.key,
                              );

                            return (
                              <CCText
                                fontSize={FontSizes[14]}
                                fontWeight="semibold"
                                style={[
                                  styles.boxTabBarLabel,
                                  focused ? styles.boxIndicator : {},
                                  {
                                    color: focused
                                      ? CCColors?.White
                                      : CCColors?.TextColor?.Primary,
                                  },
                                  itemId === 0
                                    ? styles.boxLeftBorder
                                    : itemId === totalItems - 1
                                    ? styles.boxRightBorder
                                    : null,
                                ]}>
                                {route.title}
                              </CCText>
                            );
                          }}
                          tabStyle={{
                            flex: 1,
                            height: '100%',
                            paddingTop: 45,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRightWidth: 2,
                            borderRightColor: CCColors.BorderLight,
                          }}
                        />
                      );
                    }}
                    onIndexChange={setIndex}
                  />
                }
                childrenB={
                  <Picker
                    selectedValue={selectedValue}
                    onValueChange={itemValue => {
                      trainerStore.setSelectedTrainer(itemValue);
                      setSelectedValue(itemValue);
                    }}
                    style={[
                      isMobileOrTab ? styles.dropdownMobile : styles.dropdown,
                      {flex: isMobileOrTab && 1},
                    ]}
                    itemStyle={{
                      fontSize: 10,
                      color: CCColors.TextColor.Dark,
                      fontWeight: '400',
                    }}>
                    <Picker.Item
                      label="Square Trainer"
                      value="Square Trainer"
                    />
                    <Picker.Item
                      label="Calculation Trainer-1 move"
                      value="Calculation Trainer-1 move"
                    />
                    <Picker.Item
                      label="Calculation Trainer-2 moves"
                      value="Calculation Trainer-2 moves"
                    />
                    <Picker.Item
                      label="Calculation Trainer-3 moves"
                      value="Calculation Trainer-3 moves"
                    />
                    {/* <Picker.Item
              label="Visualisation memory"
              value="Visualisation memory"
            /> */}
                    <Picker.Item
                      label="Intuition Trainer"
                      value="Intuition Trainer"
                    />
                  </Picker>
                }
              />
            </Box>
          )}
        </>
      )}

      <ConditionalRender
        condition={!isMobileOrTab}
        childrenA={<></>}
        childrenB={<>{getMobileTrainer()}</>}
      />
      {/* {getTrainer()} */}
    </Box>
  );
};

export default Visualisation;
