import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import {Image, Animated, View, Pressable} from 'react-native';
import SlideModal from '@/Atoms/SlideModal';
import React, {useState, useEffect, useRef} from 'react';
import styles from './styles';
import CCColors from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import ChatModal from './component/ChatModal';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import caissa_animation from '@/Assets/Animation/caissa_animation.riv';
import caissahello_animation from '@/Assets/Animation/CaissaHello_animation.riv';
import caissasuccess_animation from '@/Assets/Animation/CaissaSuccess_animation.riv';
import {useRive, Layout, Fit, Alignment} from '@rive-app/react-canvas';

function CaissaChatbot() {
  const [openModal, setOpenModal] = useState(false);
  const [showText, setShowText] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const widthAnim = useRef(new Animated.Value(200)).current; // Initial width
  const {isMobileorTab} = useMediaQuery();

  // Set up Rive animations
  const {RiveComponent: HelloAnimation} = useRive({
    src: caissahello_animation,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.TopCenter,
    }),
    autoplay: true,
  });

  const {RiveComponent: SuccessAnimation} = useRive({
    src: caissasuccess_animation,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.TopCenter,
    }),
    autoplay: true,
  });

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // Hide the text after 5 seconds
      setShowText(false);

      // Animate the width of chabotBg
      Animated.timing(widthAnim, {
        toValue: 70, // Final width
        duration: 500, // Duration of the animation (500ms)
        useNativeDriver: false, // We can't animate layout properties using native driver
      }).start();
    }, 5000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // Function to show the whole component when hovered/pressed
  const handleShowFull = () => {
    setIsHovered(true);
    setShowText(true);
    Animated.timing(widthAnim, {
      toValue: 200, // Expand width
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  // Function to hide the component again after hovering/pressing is finished
  const handleHidePartial = () => {
    setIsHovered(false);
    setTimeout(() => {
      setShowText(false);
      Animated.timing(widthAnim, {
        toValue: 70, // Shrink width
        duration: 300,
        useNativeDriver: false,
      }).start();
    }, 200); // Hide after 5 seconds
  };

  return (
    <>
      <ChatModal openModal={openModal} handleClose={handleClose} />
      <Box
        onMouseEnter={handleShowFull}
        onMouseLeave={handleHidePartial}
        onPress={handleOpen}
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 1000,
        }}>
        <Animated.View
          style={[
            isMobileorTab
              ? [
                  styles.chabotBgMobile,
                  {justifyContent: isHovered ? 'flex-start' : 'center'},
                ]
              : [
                  styles.chabotBg,
                  {justifyContent: isHovered ? 'flex-start' : 'center'},
                ],
            {width: widthAnim},
          ]}>
          <View
            style={{
              height: 60,
              width: 60,
              position: 'relative',
              overflow: 'hidden',
            }}>
            {HelloAnimation && (
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: isHovered ? 0 : 1,
                  pointerEvents: isHovered ? 'none' : 'auto',
                  transition: 'opacity 0.3s ease-in-out',
                }}>
                <HelloAnimation />
              </View>
            )}
            {SuccessAnimation && (
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: isHovered ? 1 : 0,
                  pointerEvents: isHovered ? 'auto' : 'none',
                  transition: 'opacity 0.3s ease-in-out',
                }}>
                <SuccessAnimation />
              </View>
            )}
          </View>
          {showText && (
            <>
              <Spacer spacing={10} horizontal />
              <CCText
                fontSize={FontSizes[18]}
                fontWeight={600}
                color={CCColors.White}
                style={{whiteSpace: 'nowrap'}}>
                Ask Caissa
              </CCText>
            </>
          )}
        </Animated.View>
      </Box>
    </>
  );
}

export default CaissaChatbot;
