import CCColors from '@/Utils/CCColors';
import {SCREEN_WIDTH, WINDOW_WIDTH, isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  tooltip: {
    backgroundColor: CCColors.Black,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxWidth: 300,
  },
  pieceToPlay: {
    padding: 10,
    fontSize: 18,
    fontWeight: '500',
    color: CCColors.Red,
  },
  pieceToPlayMobile: {
    padding: 5,
    fontSize: 14,
    fontWeight: '500',
    color: CCColors.Red,
  },
  container: {
    justifyContent: 'center',
    // marginRight: '2%',
    // paddingLeft: '8%',
    width: '50%',
  },
  puzzleId: {
    marginTop: 10,
    marginBottom: 0,
  },
  puzzleIdMobile: {
    marginTop: 0,
    marginBottom: 20,
  },
  mobileContainer: {
    width: '100%',
    justifyContent: 'center',
    marginHorizontal: '5%',
    // alignSelf: 'center',
    marginTop: 10,
  },
  boxIndicator: {
    // backgroundColor: CCColors?.Primary.Brown,
    height: '0%',
    marginBottom: 20,
    // borderRadius: 8,
  },
  boxTabBarLabel: {
    color: CCColors?.White,
    paddingBottom: 20,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    paddingHorizontal: 0,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  upgradecontainer: {
    backgroundColor: CCColors.White,
    borderRadius: 10,
    width: 362,
    height: 64,

    marginTop: 0,
    paddingTop: 0,
  },
  upgradecontainerMobile: {
    backgroundColor: CCColors.White,
    borderRadius: 10,
    width: 338,
    height: 64,

    marginTop: 0,
    paddingTop: 0,
  },
  upgrade: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    paddingVertical: 13,

    paddingHorizontal: 16,
  },
  upgradeMobile: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    paddingVertical: 13,

    paddingHorizontal: 2,
  },
  wrapper: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    width: '100%',
    height: '100%',
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  modal: {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },

  levelMobileModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,

    overflowY: 'hidden',
  },
  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: WINDOW_WIDTH / 3,
    margin: 'auto',
    left: 40,
  },
  dimension: {
    height: 440,
  },
  dimensions: {
    height: '33%',
    overflow: 'hidden',
  },
  boxLeftBorder: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  boxRightBorder: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  boxTabBar: {
    backgroundColor: CCColors?.White,
    width: '60%',
    // margin: 10,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: CCColors.Grey.Border,
    paddingHorizontal: 0,
    borderRadius: 8,
  },
});

export default styles;
