import Box from '../Box';
import {ScrollView} from 'react-native-gesture-handler';
import Feather from 'react-native-vector-icons/Feather';
import CCText from '../CCText';
import Spacer from '../Spacer';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes, WINDOW_HEIGHT} from '@/Utils/Dimensions';
import Entypo from 'react-native-vector-icons/Entypo';
import ConditionalRender from '../ConditionalRender';
import customStyles from './styles';
import React, {useEffect, useRef, useState} from 'react';
import StockfishEngine from '@/Components/StockfishEngine';
import {Switch, TouchableOpacity, View, Image} from 'react-native';
import StockfishComponent from '@/Components/StockfishEngine/StockfishComponent';
import Divider from '../Divider/Divider';
import {throttle} from 'lodash';
import ContextMenu from '@/Components/ContextMenu';
import usePromoteBranch, {
  PromoteBranchPayload,
} from '@/Hooks/ManageMoves/useManageMoves';
import {moveAnnotationsObjToDisplay} from '@/Utils/CommonUtils';

interface movesListProps {
  gameMoves: any[]; // Update the type to match your data structure
  activeMove: any; // Update the type to match your data structure
  handleChessMove: (move: any, arg1: boolean, arg2: string, arg3: any) => void; // Update the argument types and return type as needed
  handleAddAnnotation: (move: any) => void; // Update the argument type as needed
  convertToStandardEval: any; // Update the argument types and return type as needed
  evaluateMove: (move: any) => string; // Update the argument type and return type as needed
  //   customStyles?: any;
  moveIconsAndColors?: any; // Update the type to match your move icons and colors object
  moveCoords?: any[]; // Update the type to match your moveCoords data structure
  setClicked: React.Dispatch<React.SetStateAction<any>>; // Update the state type as needed
  setmoveSelected: React.Dispatch<React.SetStateAction<any>>; // Update the state type as needed
  useFeather: boolean;
  playerOneColor: any;
  playerTwoColor: any;
  main: boolean;
  fen2: any;
  isFenFromOutside?: boolean;
  setIsFenFromOutside?: () => {};
  disableArrowMove?: boolean;
  descriptionText?: string;
}

// const displayChildMove = (
//   move,
//   depth = 0,
//   branchNumber = 1,
//   onMoveClick = () => {},
// ) => {
//   // Construct the move button with indentation, depth, and numbering
//   const indentation = ' '.repeat(depth * 2); // Adjust the multiplication factor to control spacing
//   const moveLabel = `${branchNumber}. ${move.san}`;

//   // Define what happens when a move is clicked
//   const handleMoveClick = () => {
//     console.log(`Move clicked: ${move.san}`);
//     onMoveClick(move);
//   };

//   // Create a button for each move
//   return (
//     <CCButton key={move.id} onPress={handleMoveClick} style={{marginLeft: depth * 10, padding: 8, marginVertical: 4}}>
//       {indentation}{moveLabel}
//     </CCButton>
//   );
// };

const MovesList: React.FC<movesListProps> = ({
  gameMoves,
  setGameMoves,
  activeMove,
  handleChessMove = () => {},
  handleAddAnnotation = () => {},
  convertToStandardEval = () => {},
  evaluateMove = () => {},
  refetchStudy = () => {},
  descriptionText,
  //   customStyles,
  //   FontSizes,
  moveIconsAndColors,
  moveCoords,
  setClicked,
  setLoading = () => {},
  setmoveSelected,
  useFeather = false,
  playerOneColor = undefined,
  playerTwoColor = undefined,
  main = false,
  fen2,
  isFenFromOutside = false,
  setIsFenFromOutside = () => {},
  disableArrowMove = false,
}) => {
  function isWhitesTurn(fen) {
    // Split the FEN string by spaces
    const fenParts = fen.split(' ');

    // The second part of the FEN string indicates the active color
    const activeColor = fenParts[1];

    // Return true if the active color is 'w', otherwise false
    return activeColor === 'w';
  }

  console.log('gameMoves0: ', gameMoves?.[0]);
  if (
    gameMoves?.[0] &&
    gameMoves?.[0]?.fen &&
    isWhitesTurn(gameMoves?.[0]?.fen)
  ) {
    gameMoves?.unshift({});
  }
  console.log('gameMoves: ', gameMoves);
  const [indexSelected, setIndexSelected] = useState(0);
  const [stockfishHeight, setStockfishHeight] = useState(0);
  const windowHeight = WINDOW_HEIGHT - 0.15 * WINDOW_HEIGHT;
  const {
    mutate: manageBranch,
    isLoading,
    data: promotoSuccessData,
  } = usePromoteBranch();

  useEffect(() => {
    if (isLoading !== undefined) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  const handleMove = throttle((moveType: 'next' | 'prev') => {
    // setSelectedTab('GAME REVIEW');

    // switch (moveType) {
    //   case 'next':
    switch (moveType) {
      case 'next':
        handleMoveSelect(indexSelected + 1);
        // console.log(activeMove, 'activeMove');
        break;
      case 'prev':
        handleMoveSelect(indexSelected - 1);
        // console.log(activeMove, 'activeMove');
        break;

      default:
        break;
    }
  }, 300);

  useEffect(() => {
    const handleKeyDown = event => {
      if (disableArrowMove) return;
      if (event.keyCode === 37) {
        handleMove('prev');
      } else if (event.keyCode === 39) {
        handleMove('next');
      }
    };

    // Attach the event listener
    if (!disableArrowMove) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleMove]);

  useEffect(() => {
    if (promotoSuccessData) {
      refetchStudy();
    }
  }, [promotoSuccessData]);

  const handleBranchPromotion = (tempPayload: PromoteBranchPayload) => {
    manageBranch(tempPayload);
  };

  const displayChildMove = (
    move,
    currentLine = '',
    depth = 0,
    branchNumber = 1,
    prevMove = null,
    onMoveClick = () => {},
    parentIndex,
  ) => {
    // Append the current move to the line
    const isSelected = activeMove?.id === move?.id;
    console.log(move, 'displayChildMove');
    let moveDescription = (
      <CCText
        {...(isSelected ? {fontWeight: 'semibold'} : {})}
        style={isSelected ? {color: CCColors?.Primary?.Default} : {}}
        onPress={() => {
          // debugger;
          onMoveClick?.(move, prevMove);
        }}>
        {`${
          depth % 2 === 0 ? ` ${parentIndex + Math.floor(depth / 2)}.` : ''
        } ${move.san}`}
        {move?.annotation && (
          <CCText color={CCColors?.Grey.Grey} fontSize={FontSizes[11]}>
            <Spacer spacing={5} horizontal />
            {move?.annotation}
          </CCText>
          // <Entypo
          //   name="dot-single"
          //   size={25}
          //   color={updateColorWithOpacity(CCColors?.Red, 0.7)}
          //   style={{
          //     position: 'absolute',
          //     right: '-51%',
          //     top: '-69%',
          //   }}
          // />
        )}
      </CCText>
    );

    if (move?.child_moves?.length === 0) {
      moveDescription = (
        <ContextMenu
          menuOptions={[
            {
              label: 'Promote',
              onClick: () => {
                handleBranchPromotion({action: 'promote', move_id: move?.id});
              },
            },
            {
              label: 'Delete',
              onClick: () => {
                handleBranchPromotion({action: 'delete', move_id: move?.id});
              },
            },
          ]}>
          {moveDescription}
        </ContextMenu>
      );
    }
    const newLine =
      depth === 0 ? (
        <>
          {/* <CCText>...</CCText> */}
          {/* {branchNumber}. */}
          {/* {moveDescription} */}
        </>
      ) : (
        <>
          {currentLine}
          {moveDescription}
        </>
      );

    // Define what happens when a move is clicked
    const handleMoveClick = () => {
      onMoveClick?.(move, prevMove);
    };

    // If this move has no children, create a Text element for this complete line
    if (!move.child_moves || move.child_moves.length === 0) {
      return [
        <Box
          key={move.id}
          style={[{fontSize: 16}, customStyles.childSeq]}
          onPress={handleMoveClick}>
          <Divider width={1} style={{width: 20}} color={CCColors.Black} />
          <CCText></CCText>
          {newLine}
        </Box>,
      ];
    } else {
      // Otherwise, continue creating lines for each child move
      return move.child_moves.flatMap((childMove, index) =>
        displayChildMove(
          childMove,
          newLine,
          depth + 1,
          depth === 0 ? branchNumber + index + 1 : branchNumber,
          move, // passing current move as the previous move for the next level
          onMoveClick,
          parentIndex,
        ),
      );
    }
  };
  // onPress={() => {
  //   if (!gameMoves?.[index]) return;
  //   if (isWhiteMoveSelected) {
  //     handleAddAnnotation(gameMoves?.[index]);
  //   }

  //   handleChessMove(
  //     gameMoves?.[index],
  //     false,
  //     '',
  //     gameMoves?.[index - 1],
  //   );
  //   console.log(gameMoves[index]);
  //   setmoveSelected(gameMoves[index]);
  //   setClicked?.(true);
  //   setIsFenFromOutside(false);
  // }}
  const handleMoveSelect = tempIndex => {
    // const isWhiteMoveSelected = activeMove?.id
    //   ? activeMove?.id === gameMoves?.[tempIndex]?.id
    //   : activeMove?.fen === gameMoves?.[tempIndex]?.fen;

    // const isBlackMoveSelected = activeMove?.id
    //   ? activeMove?.id === gameMoves?.[tempIndex + 1]?.id
    //   : activeMove?.fen === gameMoves?.[tempIndex + 1]?.fen;
    if (!gameMoves?.[tempIndex]) return;
    // if (isWhiteMoveSelected) {
    // handleAddAnnotation(gameMoves?.[tempIndex]);
    // }

    handleChessMove(
      gameMoves?.[tempIndex],
      false,
      '',
      gameMoves?.[tempIndex - 1],
    );
    setmoveSelected?.(gameMoves?.[tempIndex]);
    setClicked?.(true);
    setIsFenFromOutside(false);
    setIndexSelected(tempIndex);
  };
  const scrollViewRef = useRef();

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [gameMoves]);
  return (
    <View style={customStyles?.playerScore}>
      <View
        onLayout={event => {
          setStockfishHeight(event.nativeEvent.layout.height);
        }}>
        <StockfishComponent
          fen={isFenFromOutside ? fen2 : activeMove?.fen}
          count={activeMove?.move_index}
        />
      </View>
      {descriptionText && (
        <Box
          style={{
            paddingVertical: 10,
            paddingHorizontal: 15,
          }}>
          <CCText fontWeight="regular" fontSize={14}>
            {descriptionText}
          </CCText>
        </Box>
      )}

      <ScrollView
        style={{height: windowHeight - stockfishHeight}}
        // ref={ref => setMovesScrollView(ref)}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        //  style={customStyles?.playerScore}
      >
        {/* <Switch
        trackColor={{false: '#767577', true: '#81b0ff'}}
        thumbColor={enableStockfish ? '#f5dd4b' : '#f4f3f4'}
        ios_backgroundColor="#3e3e3e"
        onValueChange={() => setEnableStockfish(!enableStockfish)}
        value={enableStockfish}
      /> */}
        {/*         
      <StockfishEngine
        fen={activeMove?.fen}
        depth={10}
        variations={3}
        // engineOn={enableStockfish}
        onResult={handleStockfishResult}
      /> */}
        {/* {Object.entries(moveSequences)?.map(([variationNumber, moves]) => (
        <Box key={variationNumber}>
          <CCText>Variation {variationNumber}:</CCText>
          <Box type="row-flex-start" style={{flexWrap: 'wrap'}}>
            {moves?.map((move, index) => (
              <>
                <CCText key={index}>{move}</CCText>
                <Spacer spacing={5} horizontal />
              </>
            ))}
          </Box>
        </Box>
      ))} */}
        {gameMoves?.length === 0 && (
          <CCText textAlign="center">No moves available</CCText>
        )}
        {gameMoves?.map((move, index) => {
          // Only work on even indexes to get alternate values
          let isColorMatched;
          if (playerOneColor && playerTwoColor) {
            isColorMatched = playerOneColor === playerTwoColor ? 1 : 0;
          } else {
            isColorMatched = 0;
          }
          const isWhiteMoveSelected = activeMove?.id
            ? activeMove?.id === gameMoves?.[index]?.id
            : activeMove?.fen === gameMoves?.[index]?.fen;

          const isBlackMoveSelected = activeMove?.id
            ? activeMove?.id === gameMoves?.[index + 1]?.id
            : activeMove?.fen === gameMoves?.[index + 1]?.fen;

          if (index % 2 === isColorMatched) {
            let returnDiv = [];
            if (
              gameMoves?.[index]?.annotation ||
              gameMoves?.[index]?.child_moves?.length > 0
            ) {
              returnDiv.push(
                <>
                  <Box
                    key={index}
                    onLayout={e => {
                      if (moveCoords) {
                        moveCoords[index / 2] = e.nativeEvent.layout.y;
                      }
                    }}
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between">
                    <Box
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flex={0.1}
                      style={{width: '100%', padding: 15}}>
                      <CCText
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          width: '100%',
                        }}>
                        {Math.floor(index / 2 + 1)}.
                      </CCText>
                    </Box>
                    <Box
                      style={[
                        isWhiteMoveSelected
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper1
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}
                      flex={0.46}>
                      <Box
                        flexDirection="row"
                        justifyContent="space-between"
                        style={customStyles.eachMoveInsideWrapper}
                        // flexDirection="row"

                        alignItems="center"
                        onPress={() => handleMoveSelect(index)}
                        flex={1}
                        style={{width: '100%'}}>
                        {/* {isWhiteMoveSelected && (
                          <>
                            {useFeather && (
                              <Feather
                                size={20}
                                name="edit-3"
                                color={CCColors.Primary.Brown}
                                onPress={event => {
                                  handleAddAnnotation(gameMoves?.[index]);
                                  event.stopPropagation();
                                }}
                              />
                            )}
                            <Spacer spacing={5} horizontal />
                          </>
                        )} */}
                        <CCText
                          style={{
                            marginVertical: 15,
                            flex: 0.5,
                            flexDirection: 'row',
                          }}>
                          {gameMoves?.[index]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index])
                            ]?.icon
                          }
                          {/* <Entypo
                            name="dot-single"
                            size={25}
                            color={updateColorWithOpacity(CCColors?.Red, 0.7)}
                            style={{
                              position: 'absolute',
                              right: '-8%',
                              top: '-68%',
                            }}
                          /> */}
                        </CCText>{' '}
                        {gameMoves?.[index]?.annotation_icon && (
                          <Box style={customStyles?.annotationIconsWrapper}>
                            <Image
                              resizeMode="contain"
                              style={[customStyles.annotationIcons]}
                              source={
                                moveAnnotationsObjToDisplay[
                                  gameMoves?.[index]?.annotation_icon
                                ]?.icon
                              }
                            />
                          </Box>
                        )}
                        {/* {gameMoves?.[index]?.annotation && (
                          <CCText
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              backgroundColor: updateColorWithOpacity(
                                CCColors?.Red,
                                0.7,
                              ),
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              padding: 2,
                              color: CCColors.White,
                            }}>
                            note
                          </CCText>
                        )} */}
                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval
                            ? convertToStandardEval(
                                (gameMoves?.[0]?.player === 'me' &&
                                  gameMoves?.[0]?.color === 'white') ||
                                  (gameMoves?.[2]?.player === 'me' &&
                                    gameMoves?.[2]?.color === 'white'),
                                gameMoves?.[index]?.score,
                              )
                            : null}
                        </CCText>
                      </Box>
                    </Box>
                    <Box
                      flex={0.46}
                      style={[
                        false
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper2
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}>
                      <Box
                        flexDirection="row"
                        justifyContent="flex-start"
                        // flexDirection="row"
                        style={customStyles.eachMoveInsideWrapper}
                        alignItems="center"
                        // onPress={() => handleMoveSelect(index + 1)}
                        // onPress={() => {
                        //   if (!gameMoves?.[index + 1]) return;
                        //   setIndexSelected(index + 1);
                        //   if (isBlackMoveSelected) {
                        //     handleAddAnnotation(gameMoves?.[index + 1]);
                        //   }
                        //   handleChessMove(
                        //     gameMoves?.[index + 1],
                        //     false,
                        //     '',
                        //     gameMoves?.[index],
                        //   );
                        //   if (main) {
                        //     console.log(gameMoves[index]);
                        //     setmoveSelected(gameMoves[index]);
                        //   } else {
                        //     console.log(gameMoves[index + 1]);
                        //     setmoveSelected(gameMoves[index + 1]);
                        //   }

                        //   setClicked?.(true);
                        //   setIsFenFromOutside(false);
                        // }}
                        flex={1}
                        style={{width: '100%'}}>
                        {isBlackMoveSelected && (
                          <ConditionalRender
                            condition={useFeather}
                            childrenA={
                              <>
                                <Feather
                                  size={20}
                                  name="edit-3"
                                  color={CCColors.Primary.Brown}
                                  onPress={event => {
                                    handleAddAnnotation(gameMoves?.[index + 1]);
                                    event.stopPropagation();
                                  }}
                                />
                                <Spacer spacing={5} horizontal />
                              </>
                            }
                          />
                        )}
                        <CCText
                          style={[
                            // activeMove?.fen ===
                            // gameMoves?.[index + 1]?.fen
                            //   ? customStyles?.selectedMove
                            //   : customStyles?.playerScoreEach,
                            {
                              width: '100%',
                              textAlign: 'left',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginVertical: 15,
                              flex: 0.5,
                            },
                          ]}>
                          ...
                          {/* {gameMoves?.[index + 1]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index + 1])
                            ]?.icon
                          } */}
                        </CCText>
                        {/* {gameMoves?.[index + 1]?.annotation && (
                          <CCText
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              backgroundColor: updateColorWithOpacity(
                                CCColors?.Red,
                                0.7,
                              ),
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              padding: 2,
                              color: CCColors.White,
                            }}>
                            note
                          </CCText>
                        )} */}
                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index + 1]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval(
                            (gameMoves?.[0]?.player === 'me' &&
                              gameMoves?.[0]?.color === 'white') ||
                              (gameMoves?.[2]?.player === 'me' &&
                                gameMoves?.[2]?.color === 'white'),
                            gameMoves?.[index + 1]?.score,
                          )}
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box marginLeft={'15%'}>
                    <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index]?.annotation &&
                        `${gameMoves?.[index]?.annotation} (${gameMoves?.[index]?.move})`}
                    </CCText>
                    {/* <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index + 1]?.annotation &&
                        `${gameMoves?.[index + 1]?.annotation} (${
                          gameMoves?.[index + 1]?.move
                        })`}
                    </CCText> */}

                    {gameMoves?.[index]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });
                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )}
                    {/* {gameMoves?.[index + 1]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index + 1],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });

                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )} */}
                  </Box>
                  {/* {(gameMoves?.[index + 1]?.annotation ||
                  gameMoves?.[index]?.annotation) && (
                  <>
                    <Spacer spacing={10} />
                    <CCText>Notes</CCText>
                    {gameMoves?.[index]?.annotation}
                    {gameMoves?.[index + 1]?.annotation}
                  </>
                )} */}
                </>,
              );

              returnDiv.push(
                <>
                  <Box
                    key={index}
                    onLayout={e => {
                      if (moveCoords) {
                        moveCoords[index / 2] = e.nativeEvent.layout.y;
                      }
                    }}
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between">
                    <Box
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flex={0.1}
                      style={{width: '100%', padding: 15}}>
                      <CCText
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          width: '100%',
                        }}>
                        {Math.floor(index / 2 + 1)}.
                      </CCText>
                    </Box>
                    <Box
                      style={[
                        false
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper1
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}
                      flex={0.46}>
                      <Box
                        flexDirection="row"
                        justifyContent="space-between"
                        style={customStyles.eachMoveInsideWrapper}
                        // flexDirection="row"

                        alignItems="center"
                        // onPress={() => handleMoveSelect(index)}
                        flex={1}
                        style={{width: '100%'}}>
                        {isWhiteMoveSelected && (
                          <>
                            {useFeather && (
                              <Feather
                                size={20}
                                name="edit-3"
                                color={CCColors.Primary.Brown}
                                onPress={event => {
                                  handleAddAnnotation(gameMoves?.[index]);
                                  event.stopPropagation();
                                }}
                              />
                            )}
                            <Spacer spacing={5} horizontal />
                          </>
                        )}

                        <CCText
                          style={{
                            marginVertical: 15,
                            flex: 0.5,
                            flexDirection: 'row',
                          }}>
                          ...
                          {/* {gameMoves?.[index]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index])
                            ]?.icon
                          } */}
                          {/* <Entypo
                            name="dot-single"
                            size={25}
                            color={updateColorWithOpacity(CCColors?.Red, 0.7)}
                            style={{
                              position: 'absolute',
                              right: '-8%',
                              top: '-68%',
                            }}
                          /> */}
                        </CCText>
                        {/* {gameMoves?.[index]?.annotation && (
                          <CCText
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              backgroundColor: updateColorWithOpacity(
                                CCColors?.Red,
                                0.7,
                              ),
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              padding: 2,
                              color: CCColors.White,
                            }}>
                            note
                          </CCText>
                        )} */}

                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval
                            ? convertToStandardEval(
                                (gameMoves?.[0]?.player === 'me' &&
                                  gameMoves?.[0]?.color === 'white') ||
                                  (gameMoves?.[2]?.player === 'me' &&
                                    gameMoves?.[2]?.color === 'white'),
                                gameMoves?.[index]?.score,
                              )
                            : null}
                        </CCText>
                      </Box>
                    </Box>
                    <Box
                      flex={0.46}
                      style={[
                        isBlackMoveSelected
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper2
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}>
                      <Box
                        flexDirection="row"
                        justifyContent="flex-start"
                        // flexDirection="row"
                        style={customStyles.eachMoveInsideWrapper}
                        alignItems="center"
                        onPress={() => handleMoveSelect(index + 1)}
                        // onPress={() => {
                        //   if (!gameMoves?.[index + 1]) return;
                        //   setIndexSelected(index + 1);
                        //   if (isBlackMoveSelected) {
                        //     handleAddAnnotation(gameMoves?.[index + 1]);
                        //   }
                        //   handleChessMove(
                        //     gameMoves?.[index + 1],
                        //     false,
                        //     '',
                        //     gameMoves?.[index],
                        //   );
                        //   if (main) {
                        //     console.log(gameMoves[index]);
                        //     setmoveSelected(gameMoves[index]);
                        //   } else {
                        //     console.log(gameMoves[index + 1]);
                        //     setmoveSelected(gameMoves[index + 1]);
                        //   }

                        //   setClicked?.(true);
                        //   setIsFenFromOutside(false);
                        // }}
                        flex={1}
                        style={{width: '100%'}}>
                        {isBlackMoveSelected && (
                          <ConditionalRender
                            condition={useFeather}
                            childrenA={
                              <>
                                <Feather
                                  size={20}
                                  name="edit-3"
                                  color={CCColors.Primary.Brown}
                                  onPress={event => {
                                    handleAddAnnotation(gameMoves?.[index + 1]);
                                    event.stopPropagation();
                                  }}
                                />
                                <Spacer spacing={5} horizontal />
                              </>
                            }
                          />
                        )}
                        <CCText
                          style={[
                            // activeMove?.fen ===
                            // gameMoves?.[index + 1]?.fen
                            //   ? customStyles?.selectedMove
                            //   : customStyles?.playerScoreEach,
                            {
                              width: '100%',
                              textAlign: 'left',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              // marginVertical: 15,
                              flex: 0.5,
                            },
                          ]}>
                          {gameMoves?.[index + 1]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index + 1])
                            ]?.icon
                          }
                        </CCText>{' '}
                        {gameMoves?.[index + 1]?.annotation_icon && (
                          <Box style={customStyles?.annotationIconsWrapper}>
                            <Image
                              resizeMode="contain"
                              style={[customStyles.annotationIcons]}
                              source={
                                moveAnnotationsObjToDisplay[
                                  gameMoves?.[index + 1]?.annotation_icon
                                ]?.icon
                              }
                            />
                          </Box>
                        )}
                        {/* {gameMoves?.[index + 1]?.annotation && (
                          <CCText
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              backgroundColor: updateColorWithOpacity(
                                CCColors?.Red,
                                0.7,
                              ),
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              padding: 2,
                              color: CCColors.White,
                            }}>
                            note
                          </CCText>
                        )} */}
                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index + 1]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval(
                            (gameMoves?.[0]?.player === 'me' &&
                              gameMoves?.[0]?.color === 'white') ||
                              (gameMoves?.[2]?.player === 'me' &&
                                gameMoves?.[2]?.color === 'white'),
                            gameMoves?.[index + 1]?.score,
                          )}
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box marginLeft={'15%'}>
                    {/* <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index]?.annotation &&
                        `${gameMoves?.[index]?.annotation} (${gameMoves?.[index]?.move})`}
                    </CCText> */}
                    <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index + 1]?.annotation &&
                        `${gameMoves?.[index + 1]?.annotation} (${
                          gameMoves?.[index + 1]?.move
                        })`}
                    </CCText>

                    {/* {gameMoves?.[index]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });
                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )} */}
                    {gameMoves?.[index + 1]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index + 1],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });

                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )}
                  </Box>
                  {/* {(gameMoves?.[index + 1]?.annotation ||
                  gameMoves?.[index]?.annotation) && (
                  <>
                    <Spacer spacing={10} />
                    <CCText>Notes</CCText>
                    {gameMoves?.[index]?.annotation}
                    {gameMoves?.[index + 1]?.annotation}
                  </>
                )} */}
                </>,
              );
            }
            // if (gameMoves?.[index + 1]?.child_moves?.length > 0) {

            // }
            //  {
            else {
              returnDiv.push(
                <>
                  <Box
                    key={index}
                    onLayout={e => {
                      if (moveCoords) {
                        moveCoords[index / 2] = e.nativeEvent.layout.y;
                      }
                    }}
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between">
                    <Box
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flex={0.1}
                      style={{width: '100%', padding: 15}}>
                      <CCText
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          width: '100%',
                        }}>
                        {Math.floor(index / 2 + 1)}.
                      </CCText>
                    </Box>
                    <Box
                      style={[
                        isWhiteMoveSelected
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper1
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}
                      flex={0.46}>
                      <Box
                        flexDirection="row"
                        justifyContent="space-between"
                        style={customStyles.eachMoveInsideWrapper}
                        // flexDirection="row"

                        alignItems="center"
                        onPress={() => handleMoveSelect(index)}
                        flex={1}
                        style={{width: '100%'}}>
                        {isWhiteMoveSelected && (
                          <>
                            {useFeather && (
                              <Feather
                                size={20}
                                name="edit-3"
                                color={CCColors.Primary.Brown}
                                onPress={event => {
                                  handleAddAnnotation(gameMoves?.[index]);
                                  event.stopPropagation();
                                }}
                              />
                            )}
                            <Spacer spacing={5} horizontal />
                          </>
                        )}
                        <CCText
                          style={{
                            marginVertical: 15,
                            flex: 0.5,
                            flexDirection: 'row',
                          }}>
                          {gameMoves?.[index]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index])
                            ]?.icon
                          }
                          {/* <Entypo
                          name="dot-single"
                          size={25}
                          color={updateColorWithOpacity(CCColors?.Red, 0.7)}
                          style={{
                            position: 'absolute',
                            right: '-8%',
                            top: '-68%',
                          }}
                        /> */}
                        </CCText>{' '}
                        {gameMoves?.[index]?.annotation_icon && (
                          <Box style={customStyles?.annotationIconsWrapper}>
                            <Image
                              resizeMode="contain"
                              style={[customStyles.annotationIcons]}
                              source={
                                moveAnnotationsObjToDisplay[
                                  gameMoves?.[index]?.annotation_icon
                                ]?.icon
                              }
                            />
                          </Box>
                        )}
                        {/* {gameMoves?.[index]?.annotation && (
                        <CCText
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: updateColorWithOpacity(
                              CCColors?.Red,
                              0.7,
                            ),
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            padding: 2,
                            color: CCColors.White,
                          }}>
                          note
                        </CCText>
                      )} */}
                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval
                            ? convertToStandardEval(
                                (gameMoves?.[0]?.player === 'me' &&
                                  gameMoves?.[0]?.color === 'white') ||
                                  (gameMoves?.[2]?.player === 'me' &&
                                    gameMoves?.[2]?.color === 'white'),
                                gameMoves?.[index]?.score,
                              )
                            : null}
                        </CCText>
                      </Box>
                    </Box>
                    <Box
                      flex={0.46}
                      style={[
                        isBlackMoveSelected
                          ? customStyles?.selectedMove
                          : index === 0 ||
                            (playerOneColor === playerTwoColor && index === 1)
                          ? customStyles.eachMoveWrapper2
                          : customStyles.eachMoveWrapper,
                        {
                          height: '100%',
                        },
                      ]}>
                      <Box
                        flexDirection="row"
                        justifyContent="flex-start"
                        // flexDirection="row"
                        style={customStyles.eachMoveInsideWrapper}
                        alignItems="center"
                        onPress={() => handleMoveSelect(index + 1)}
                        // onPress={() => {
                        //   if (!gameMoves?.[index + 1]) return;
                        //   setIndexSelected(index + 1);
                        //   if (isBlackMoveSelected) {
                        //     handleAddAnnotation(gameMoves?.[index + 1]);
                        //   }
                        //   handleChessMove(
                        //     gameMoves?.[index + 1],
                        //     false,
                        //     '',
                        //     gameMoves?.[index],
                        //   );
                        //   if (main) {
                        //     console.log(gameMoves[index]);
                        //     setmoveSelected(gameMoves[index]);
                        //   } else {
                        //     console.log(gameMoves[index + 1]);
                        //     setmoveSelected(gameMoves[index + 1]);
                        //   }

                        //   setClicked?.(true);
                        //   setIsFenFromOutside(false);
                        // }}
                        flex={1}
                        style={{width: '100%'}}>
                        {isBlackMoveSelected && (
                          <ConditionalRender
                            condition={useFeather}
                            childrenA={
                              <>
                                <Feather
                                  size={20}
                                  name="edit-3"
                                  color={CCColors.Primary.Brown}
                                  onPress={event => {
                                    handleAddAnnotation(gameMoves?.[index + 1]);
                                    event.stopPropagation();
                                  }}
                                />
                                <Spacer spacing={5} horizontal />
                              </>
                            }
                          />
                        )}
                        <CCText
                          style={[
                            // activeMove?.fen ===
                            // gameMoves?.[index + 1]?.fen
                            //   ? customStyles?.selectedMove
                            //   : customStyles?.playerScoreEach,
                            {
                              width: '100%',
                              textAlign: 'left',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              // marginVertical: 15,
                              flex: 0.5,
                            },
                          ]}>
                          {gameMoves?.[index + 1]?.move}{' '}
                          {
                            moveIconsAndColors?.[
                              evaluateMove(gameMoves?.[index + 1])
                            ]?.icon
                          }
                        </CCText>{' '}
                        {gameMoves?.[index + 1]?.annotation_icon && (
                          <Box style={customStyles?.annotationIconsWrapper}>
                            <Image
                              resizeMode="contain"
                              style={[customStyles.annotationIcons]}
                              source={
                                moveAnnotationsObjToDisplay[
                                  gameMoves?.[index + 1]?.annotation_icon
                                ]?.icon
                              }
                            />
                          </Box>
                        )}
                        {/* {gameMoves?.[index + 1]?.annotation && (
                        <CCText
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: updateColorWithOpacity(
                              CCColors?.Red,
                              0.7,
                            ),
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            padding: 2,
                            color: CCColors.White,
                          }}>
                          note
                        </CCText>
                      )} */}
                        <CCText
                          style={{
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {isNaN(
                            parseInt(
                              gameMoves?.[index + 1]?.time_left_on_clock / 60,
                            )
                              .toString()
                              .padStart(2, '0'),
                          )
                            ? ''
                            : parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock / 60,
                              )
                                .toString()
                                .padStart(2, '0') +
                              ':' +
                              parseInt(
                                gameMoves?.[index + 1]?.time_left_on_clock % 60,
                              )
                                .toString()
                                .padStart(2, '0')}
                        </CCText>
                        <CCText
                          style={{
                            textAlign: 'right',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 15,
                            fontSize: FontSizes[10],
                            color: CCColors.TextColor.Grey,
                            flex: 0.5,
                          }}>
                          {convertToStandardEval(
                            (gameMoves?.[0]?.player === 'me' &&
                              gameMoves?.[0]?.color === 'white') ||
                              (gameMoves?.[2]?.player === 'me' &&
                                gameMoves?.[2]?.color === 'white'),
                            gameMoves?.[index + 1]?.score,
                          )}
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box marginLeft={'15%'}>
                    <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index]?.annotation &&
                        `${gameMoves?.[index]?.annotation} (${gameMoves?.[index]?.move})`}
                    </CCText>
                    <CCText
                      color={CCColors?.Grey.Grey}
                      fontSize={FontSizes[11]}>
                      {gameMoves?.[index + 1]?.annotation &&
                        `${gameMoves?.[index + 1]?.annotation} (${
                          gameMoves?.[index + 1]?.move
                        })`}
                    </CCText>

                    {gameMoves?.[index]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });
                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )}
                    {gameMoves?.[index + 1]?.child_moves?.length > 0 &&
                      displayChildMove(
                        gameMoves?.[index + 1],
                        '',
                        0,
                        1,
                        null,
                        (currMove, lastMove) => {
                          handleChessMove(currMove, false, '', lastMove);

                          setmoveSelected?.({
                            ...currMove,
                            newFen: currMove?.fen,
                            lastFen: lastMove?.fen,
                            move: currMove?.san,
                          });

                          setIsFenFromOutside(false);
                        },
                        Math.floor(index / 2 + 1),
                      )}
                  </Box>
                  {/* {(gameMoves?.[index + 1]?.annotation ||
                gameMoves?.[index]?.annotation) && (
                <>
                  <Spacer spacing={10} />
                  <CCText>Notes</CCText>
                  {gameMoves?.[index]?.annotation}
                  {gameMoves?.[index + 1]?.annotation}
                </>
              )} */}
                </>,
              );
            }
            return returnDiv;
            // }
          } else {
            return null;
          }
        })}
      </ScrollView>
    </View>
  );
};

export default React.memo(MovesList);
