import React, {useState, useEffect, useRef} from 'react';
import {
  ScrollView,
  Image,
  Pressable,
  Animated,
  TouchableOpacity,
  View,
} from 'react-native';
import CCText from '@/Atoms/CCText';
import blindFoldStyles from '../../styles';
import useGetCalculation from '@/Hooks/getCalculationApi/useGetCalculationApi';
import CCButton from '@/Atoms/CCButton';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import {notations} from '../../notations';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import Box from '@/Atoms/Box';
import wrongSound from '@/Assets/Sounds/wrong.mp3';
import successSound from '@/Assets/Sounds/success.mp3';
import useSound from 'use-sound';
import useVerifyCalculationTrainerResult from '@/Hooks/CalculationTrainerApi/verifyCalculationTrainerResult';
import Divider from '@/Atoms/Divider/Divider';
import Spacer from '@/Atoms/Spacer';
import crossCircle from '@/Assets/Images/Svgs/crossCircle.svg';
import {
  isPhone,
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  WINDOW_WIDTH,
  FontSizes,
} from '@/Utils/Dimensions';
import thumsup from '@/Assets/Images/Svgs/thumsup.svg';
import thumsupActive from '@/Assets/Images/Svgs/thumsupActive.svg';
import thumsdown from '@/Assets/Images/Svgs/thumsdown.svg';
import thumsdownActive from '@/Assets/Images/Svgs/thumsdownActive.svg';
import useAnimation from '@/Hooks/useAnimation';
import styles from './styles';

import Icon from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import BottomSheetCopy from '../BottomSheetCopy';

import Tooltip from '@/Atoms/Tooltip';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import CCLoader from '@/Atoms/CCLoader';
import SlideModal from '@/Atoms/SlideModal';
import LevelModal, {LevelInfoBox, UpgradeLevel, getLevel} from '../LevelModal';
import {TabBar, TabView} from 'react-native-tab-view';
import {renderBoxTabBar} from '@/Atoms/CCTabs';
import SubscriptionModal from '@/Screens/Subscription/components/Modals';
import InfoIcon, {CustomInfoHoverBox} from '@/Atoms/InfoIcon';
import usePuzzleFeedback from '@/Hooks/FeedbackApi/useFeedback';
import {HintForIncorrectAttempt} from '@/Screens/Puzzles/next';
import ChessboardWithArrow from '@/Components/ChessboardWithArrow';

const CalculationTrainer = ({
  selectedValue,
  movesCount,
  setShowDropDown,
  dashboardData,
}) => {
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState<string>('');
  const [transcript, setTranscript] = useState<string>('');
  const [calcFen, setCalcFen] = useState<string>('');
  const [moveSequence, setMoveSequence] = useState<string[]>([]);
  const [correctAnswer, setCorrectAnswer] = useState<string>('');
  const [speechAnswer, setSpeechAnswer] = useState<string>('');
  const [puzzleId, setPuzzleId] = useState<any>(null);
  const [colorPiece, setColorPiece] = useState<string>('White');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [speech, setSpeech] = useState<any>('');
  const [timer, setTimer] = useState<number>(0);
  const [stop, setStop] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isConvertingToText, setIsConvertingToText] = useState<boolean>(false);
  const [rerender, setRerender] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [fullHint, setFullHint] = useState<string>('');
  const [fullHint2, setFullHint2] = useState<string>('');
  const [attemptCounter, setAttemptCounter] = useState<number>(0);
  const [attemptId, setAttemptId] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const [startTraining, setStartTraining] = useState<boolean>(false);
  const {isMobileOrTab} = useMediaQuery();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [state2Visible, setState2Visible] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [micPermission, setMicPermission] = useState(null);
  const [calcMoves, setCalcMoves] = useState<string>('1 move');
  const [showLevelModal, setShowLevelModal] = useState<boolean>(true);
  const [accuracy, setAccuracy] = useState(0);
  const [incorrectSound] = useSound(wrongSound);
  const [rightSound] = useSound(successSound);
  const [noOfMoves, setNoOfMoves] = useState(isMobileOrTab ? movesCount : 1);
  const chessboardColorTheme = useChessboardColorTheme();
  const [isUpgradeVisible, setIsUpgradeVisible] = useState(false);
  const [threshold, setThreshold] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [recognition, setRecognition] = useState();
  const [feedback, setFeedback] = useState<number>(0);
  const [goodFeedback, setGoodFeedback] = useState<number>(0);
  const [badFeedback, setBadFeedback] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [showChange, setShowChange] = useState(false);
  const [showIncorrectHintModal, setShowIncorrectHintModal] =
    useState<boolean>(false);
  const [disabledTabs, setDisabledTabs] = useState<boolean>(false);
  const [isNan, setisNan] = useState(false);
  const [timeoutId, setTimeOutId] = useState(null);
  const [resultOpacityAnimStyle, startResultOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 1,
    end: 1,
    repeat: true,
    numberOfReps: 2,
    inputRange: [0, 0.1],
    duration: 1000,
  });
  const [objectData, setObjectData] = useState([]);

  const setStreakDetailsSummary = useLoggedInUserStore(
    state => state.setStreakDetailsSummary,
  );
  const setStreakConfig = useLoggedInUserStore(state => state.setStreakConfig);

  const startListening = () => {
    let recognition = new window.webkitSpeechRecognition();
    setRecognition(recognition);
    recognition.continuous = true;
    recognition.interimResults = true;
    let interimTranscript = '';

    recognition.onstart = () => {
      setSpeech('');
      setIsListening(true);
      let id = setTimeout(() => {
        stopListening();
      }, 5000);
      setTimeOutId(id);
    };

    recognition.onresult = event => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript + ' ';
        } else {
          interimTranscript += event.results[i][0].transcript + ' ';
        }
      }
      setTranscript(finalTranscript.trim());
      setSpeech(interimTranscript.trim());
    };

    recognition.onend = () => {
      setIsListening(false);
      clearTimeout(timeoutId);
      setIsVisible(false);
    };

    recognition.start();
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();

      setIsListening(false);
      clearTimeout(timeoutId);
      setTimer(0);
    }
  };
  const [routes] = React.useState(
    [
      {key: '1 move', title: '1 move'},
      {key: '2 moves', title: '2 moves'},
      {key: '3 moves', title: '3 moves'},
    ]?.filter(i => i),
  );
  const {
    mutate: feedBack,
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = usePuzzleFeedback();

  async function handleSubmit(selectedFeedback: number) {
    try {
      await feedBack({
        feedback: selectedFeedback,
        puzzle_id: puzzleId,
        userid: userId,
        attempt_id: attemptId,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setCorrectAnswer('');
    setCount(0);
    setVisible(true);
  }, [selectedValue]);
  useEffect(() => {
    if (fullHint) {
      setShowIncorrectHintModal(true);
    }
  }, [fullHint]);

  const handleOnSuccessfulSubmit = data => {
    setLimitType(data?.limit_type);
    setFullHint(data?.hint_response);

    setFullHint2(data?.hint_response2);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    if (data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
      return;
    }
    if (data?.streak_details_summary) {
      setStreakDetailsSummary(data?.streak_details_summary);
    }
    if (data?.streak_details) {
      setStreakConfig(data?.streak_details);
    }
    if (data?.level) {
      setLevel(data?.level);
    }
    setCount(prev => prev + 1);

    if (data?.is_illegal_move === 1) {
      setCorrectAnswer('Please enter legal move');
    }
    if (data?.no_of_attempts) {
      setAttemptCounter(data?.no_of_attempts);
    }
    if (data?.attempt_id) {
      setAttemptId(data?.attempt_id);
    }
    if (!data.success) {
      setCorrectAnswer('Incorrect move!');
      incorrectSound();
      setValue('');
    } else {
      setCorrectAnswer('Correct!');
      setAttemptCounter(0);
      rightSound();
    }
  };

  const handleOnFailedSubmit = error => {
    console.log('verification error', error);
  };

  const {
    mutate: verifyPuzzle,
    data: verificationResponse,
    error: errorResponse,
    isLoading: verificationLoading,
  } = useVerifyCalculationTrainerResult(
    handleOnSuccessfulSubmit,
    handleOnFailedSubmit,
  );

  const percentageConfig = () => {
    return `To advance to the next level, you need to solve at least ${
      Number(threshold) / 10
    } out of ${attempts} chess positions correctly on your first attempt.`;
  };
  const {
    mutate: getCalculation,
    data,
    error,
    isLoading,
  } = useGetCalculation(handleOnSuccess, handleOnError);
  useEffect(() => {
    if (dashboardData?.user?.is_staff === false) {
      setDisabledTabs(true);
    } else {
      setDisabledTabs(false);
    }
  }, [dashboardData]);
  function handleOnSuccess(data) {
    const previousLevel = level;
    const nextThreshold = data?.next_threshold;
    const attempts = data?.positions_count;
    const levelApi = data?.level;
    const accuracyApi = parseInt(data?.accuracy);
    const solvedInFirstAttempt = data?.last_entries.map(
      plan => plan.solved_in_first_attempt,
    );
    setCalcFen(data.fen);
    setMoveSequence(data.move_sequence);
    setPuzzleId(data.data[0].pk);
    setColorPiece(data.fen.split(' ')[1] == 'w' ? 'white' : 'black');
    setUserId(data.data[0].fields.userid);
    setAttemptCounter(data?.fields?.no_of_attempts || 0);
    setLevel(levelApi);
    setAccuracy(accuracyApi);
    if (data?.level - previousLevel === 1) {
      setIsUpgradeVisible(true);
    }
    setThreshold(nextThreshold);
    setAttempts(attempts);
    setObjectData(solvedInFirstAttempt);
  }
  function handlePressOnGetLevel() {
    setShowLevelModal(true);
  }

  useEffect(() => {
    setIsUpgradeVisible(true);
    const timeoutId = setTimeout(() => {
      setIsUpgradeVisible(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [level]);

  function handleOnError(error) {
    console.log('error while fetching calculation data', error);
  }

  const onStartRecordHandler = () => {
    setIsRecording(true);
    startListening();
    const id = setInterval(() => {
      setTimer(prev => prev + 1);
    }, 1000);

    const timerTimeout = setTimeout(() => {
      clearInterval(id);
      setTimer(0);
      stopListening();
      setIsRecording(false);
    }, 5000);

    return () => {
      clearInterval(id);
      clearTimeout(timerTimeout);
    };
  };
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'puzzle' : 'puzzles'
  } is exhausted. Upgrade now to continue with the trainer.`;

  const onStopRecordHandler = () => {
    let recognition = new window.webkitSpeechRecognition();
    recognition.stop();
    stopListening();
    setIsRecording(false);
    setIsConvertingToText(true);
    setTimeout(() => {
      setIsConvertingToText(false);
    }, 500);
  };

  useEffect(() => {
    getCalculation(noOfMoves);
    setValue('');
    setCorrectAnswer('');
  }, [noOfMoves, rerender]);

  const moves = moveSequence;

  const numberRegex = /\d+/g;

  let numberArray = [];
  let moveArray = [];
  if (Array.isArray(moves)) {
    moves?.forEach(move => {
      const numberMatch = move.match(numberRegex);

      if (numberMatch && numberMatch.length > 0) {
        numberArray.push(numberMatch[0]);
      }

      const moveWithoutEllipsis = move.replace('...', '').trim();
      moveArray.push(moveWithoutEllipsis);
    });
  }

  let firstNumber = numberArray[0];
  let secondNumber = numberArray[1];
  let sequenceArray = moveArray.map(item => item.split(' ')[1]);
  sequenceArray.push(
    <ConditionalRender
      condition={!isInputFocused}
      childrenA={
        <Tooltip
          position={!isMobileOrTab ? 'left' : 'bottom'}
          text={
            <CCText color={CCColors.White} fontSize={14} fontWeight="regular">
              What's the next best move?
            </CCText>
          }
          children={toggleEvent => {
            return (
              <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                <TextInput
                  // autoFocus={true}

                  onFocus={() => {
                    setIsInputFocused(true);
                  }}
                  onBlur={() => {
                    setIsInputFocused(false);
                  }}
                  value={value}
                  onChangeText={setValue}
                  style={{
                    outlineColor: 'transparent',
                    width: '130%',
                  }}
                  placeholder="Enter answer"
                  placeholderTextColor={`${CCColors.TextColor.Dark}40`}
                />
              </View>
            );
          }}
        />
      }
      childrenB={
        <TextInput
          autoFocus={true}
          onSubmitEditing={() => {
            verifyPuzzle({
              puzzleId: puzzleId,
              move: value,
              moves_count: noOfMoves,
            });
          }}
          onFocus={() => {
            setIsInputFocused(true);
          }}
          onBlur={() => {
            setIsInputFocused(false);
          }}
          value={value}
          onChangeText={setValue}
          style={{
            outlineColor: 'transparent',
            width: '100%',
          }}
          placeholder="Enter answer"
          placeholderTextColor={`${CCColors.TextColor.Dark}40`}
        />
      }
    />,
  );

  if (firstNumber === secondNumber) {
    numberArray.push(parseInt(numberArray[numberArray.length - 1]) + 1);
  } else {
    sequenceArray.unshift(<Box flex={1}></Box>);
    numberArray.push(parseInt(numberArray[numberArray.length - 1]));
  }
  function textToNotations(sText: string) {
    if (sText === undefined || sText === '') {
      return '';
    }

    let text = sText.toLowerCase();
    let notation = '';
    const words = text.split(' ');

    for (let i = 0; i < words.length; i++) {
      const word = words[i];

      if (word === 'from') {
        i = i + 2;
        continue;
      } else if (notations[word]) {
        notation += notations[word];
      } else if (/^[a-h][1-8]$/.test(word)) {
        notation += word;
      }
    }

    return notation.trim();
  }

  useEffect(() => {
    let result = textToNotations(transcript);
    setValue(result);
    setIsRecording(false);
    setIsConvertingToText(false);
    setSpeechAnswer(result);
  }, [transcript]);

  const handleMicPermission = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({name: 'microphone'})
        .then(permissionStatus => {
          setMicPermission(permissionStatus.state);

          permissionStatus.onchange = () => {
            setMicPermission(permissionStatus.state);
          };
        })
        .catch(error => {
          console.error('Error checking microphone permission:', error);
        });
    } else {
      console.warn('Permissions API not supported');
    }
  };
  useEffect(() => {
    setVisible(true);
    handleMicPermission();
    const id = setTimeout(() => {
      setShowLevelModal(false);
    }, 5000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (!isMobileOrTab) {
      switch (index) {
        case 0:
          setNoOfMoves(1);
          setShowChange(false);
          setGoodFeedback(0);
          setBadFeedback(0);
          break;
        case 1:
          setNoOfMoves(2);
          setShowChange(false);
          setGoodFeedback(0);
          setBadFeedback(0);
          break;
        case 2:
          setNoOfMoves(3);
          setShowChange(false);
          setGoodFeedback(0);
          setBadFeedback(0);
          break;
      }
    }
  }, [index]);

  const bottomSheetState1 = () => {
    return (
      <Box>
        <Box flexDirection="row" margin={10}>
          <CCText
            fontSize={16}
            textAlign="center"
            style={{flex: 1}}
            color={CCColors.TextColor.Dark}>
            Guess the next best move
          </CCText>
          <Pressable
            onPress={() => setIsVisible(false)}
            style={{alignSelf: 'flex-end', flex: 0.1}}>
            <AntDesign name="close" size={20} aria-label="close" />
          </Pressable>
        </Box>
        <Box flexDirection="row" justifyContent="center" margin={20}>
          <MaterialIcons
            color="#E17846"
            name="multitrack-audio"
            size={20}
            aria-label="recording audio"
            style={{alignSelf: 'center'}}
          />
          <MaterialIcons
            color="#E17846"
            name="multitrack-audio"
            size={20}
            aria-label="recording audio"
            style={{alignSelf: 'center'}}
          />
        </Box>
        <FontAwesome
          onPress={() => {
            setState2Visible(true);
          }}
          color="#E17846"
          name="stop-circle"
          size={48}
          aria-label="stop-recording"
          style={{
            flex: 1,
            margin: 20,
            alignSelf: 'center',
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (Number.isNaN(numberArray[index]) || numberArray[index] === undefined) {
      setisNan(true);
    } else {
      setisNan(false);
    }
  }, [numberArray, index]);
  const bottomSheetState2 = () => {
    return (
      <Box flexDirection="row" margin={30} gap={10}>
        <FontAwesome name="refresh" size={20} aria-label="converting" />
        <CCText color={CCColors.TextColor.Dark} fontSize={16}>
          Converting audio into text ...
        </CCText>
      </Box>
    );
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <CCLoader loading={isLoading} />
      {!isMobileOrTab && <Spacer spacing={15} />}
      <Box style={styles.wrapper}>
        <Box
          style={{
            position: !isMobileOrTab ? 'relative' : 'absolute',
            marginTop: isMobileOrTab ? 0 : 25,
            width: '88vw',
            justifyContent: 'space-between',
          }}
          flexDirection={isMobileOrTab ? 'column' : 'row'}
          // justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="wrap">
          {!isMobileOrTab && showLevelModal && (
            <SlideModal
              visible={showLevelModal}
              type="center"
              customStyle={styles.levelModalCustom}>
              <Box style={styles.modal}>
                <LevelModal
                  level={level}
                  onClose={() => setShowLevelModal(false)}
                  config={percentageConfig}
                  customStyl={styles.dimension}
                  custombrownbg={styles.dimensions}
                  accuracy={accuracy}
                  objectData={objectData}
                  isCalculationTrainer={showLevelModal}
                />
              </Box>
            </SlideModal>
          )}
          {isMobileOrTab && showLevelModal && (
            <SlideModal
              visible={showLevelModal}
              type="center"
              customStyle={styles.levelMobileModalCustom}>
              <Box
                style={{
                  padding: 0,
                  margin: 0,
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}>
                <LevelModal
                  level={level}
                  accuracy={accuracy}
                  config={percentageConfig}
                  onClose={() => setShowLevelModal(false)}
                  objectData={objectData}
                  isCalculationTrainer={showLevelModal}
                />
              </Box>
            </SlideModal>
          )}
          <Box
            flex={0.9}
            overflow="hidden"
            style={{
              alignSelf: 'flex-start',
            }}>
            {isMobileOrTab && startTraining && (
              <Box
                style={{
                  width: SCREEN_WIDTH,
                  height: '100%',
                  overflow: 'hidden',
                }}>
                <ChessboardWithArrow
                  position={calcFen ? calcFen : 'start'}
                  disabled={true}
                  showBoardNotation={true}
                  boardOrientation={(
                    colorPiece[0].toUpperCase() +
                    colorPiece.slice(1, colorPiece.length)
                  ).toLowerCase()}
                  colorTheme={chessboardColorTheme}
                />
              </Box>
            )}
            {!isMobileOrTab && (
              <Box
                style={{
                  maxWidth: 'calc(100vh - 180px)',
                }}>
                <ChessboardWithArrow
                  position={calcFen ? calcFen : 'start'}
                  disabled={true}
                  showBoardNotation={true}
                  boardOrientation={(
                    colorPiece[0].toUpperCase() +
                    colorPiece.slice(1, colorPiece.length)
                  ).toLowerCase()}
                  colorTheme={chessboardColorTheme}
                />
              </Box>
            )}

            <ConditionalRender
              condition={attemptCounter >= 1}
              childrenA={
                <>
                  <Animated.View
                    style={[
                      resultOpacityAnimStyle,
                      {
                        backgroundColor: CCColors.Red,
                        maxWidth: 'calc(100vh - 180px)',
                      },
                    ]}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      marginHorizontal={isMobileOrTab && 'auto'}>
                      <Image
                        resizeMethod="resize"
                        source={require('@/Assets/Images/Svgs/hint.svg')}
                        alt="hint"
                        style={{width: 24, height: 24, marginLeft: 10}}
                      />
                      {correctAnswer && (
                        <>
                          <Box
                            flex={correctAnswer ? 1 : 0.96}
                            style={{
                              height: isMobileOrTab ? 40 : 29,
                              marginBottom: 4,
                            }}>
                            <CCText
                              textAlign="left"
                              style={{
                                padding: isMobileOrTab ? 10 : 7,
                                fontSize: 16,
                                fontWeight: '500',
                                marginRight: 10,
                              }}
                              color={CCColors.White}>
                              {correctAnswer}
                            </CCText>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Animated.View>
                </>
              }
              childrenB={
                <>
                  <Animated.View
                    style={[
                      resultOpacityAnimStyle,
                      {
                        backgroundColor:
                          correctAnswer === 'Correct!'
                            ? CCColors.Primary.lightBrown
                            : CCColors.Red,
                        maxWidth: 'calc(100vh - 180px)',
                      },
                    ]}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      marginHorizontal={isMobileOrTab && 'auto'}>
                      {correctAnswer && (
                        <>
                          <Box
                            flex={correctAnswer ? 1 : 0.96}
                            style={{
                              height: isMobileOrTab ? 40 : 29,
                              marginBottom: 4,
                            }}>
                            <CCText
                              textAlign="left"
                              style={{
                                padding: isMobileOrTab ? 10 : 7,
                                fontSize: 16,
                                fontWeight: '500',
                                marginRight: 10,
                              }}
                              color={
                                correctAnswer === 'Correct!'
                                  ? CCColors.Green
                                  : CCColors.White
                              }>
                              {correctAnswer}
                            </CCText>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Animated.View>
                </>
              }
            />
          </Box>

          <Box
            gap={10}
            style={[
              !isMobileOrTab ? styles.container : styles.mobileContainer,
            ]}>
            {fullHint && showIncorrectHintModal ? (
              <Box
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <HintForIncorrectAttempt
                  borderColor={CCColors.White}
                  result={fullHint}
                  result2={fullHint2}
                  setShowIncorrectHintModal={setShowIncorrectHintModal}
                  handleSubmit={handleSubmit}
                  showChange={showChange}
                  setShowChange={setShowChange}
                  goodFeedback={goodFeedback}
                  badFeedback={badFeedback}
                  setGoodFeedback={setGoodFeedback}
                  setBadFeedback={setBadFeedback}
                  disabled={disabledTabs}
                  // resultOpacityAnimStyle={resultOpacityAnimStyle}
                />
              </Box>
            ) : (
              <>
                {' '}
                {isMobileOrTab && !startTraining && (
                  <>
                    {!startTraining && isMobileOrTab && (
                      <Box
                        style={{
                          // width: '90vw',
                          justifyContent: 'center',
                        }}>
                        <Box
                          style={{
                            flexDirection: 'column',
                            // alignItems: 'flex-start',
                            alignItems: 'justify',
                            marginTop: 28,
                            gap: 5,
                          }}>
                          <CCText
                            fontSize={FontSizes[20]}
                            fontWeight={500}
                            color={CCColors.Black}
                            textAlign={'justify'}>
                            {selectedValue}
                          </CCText>
                          <CCText
                            color={CCColors.TextColor.LightGrey}
                            fontSize={14}
                            fontWeight="regular"
                            textAlign="justify"
                            style={{
                              width: '100%',
                              flexWrap: 'wrap',
                              marginBottom: isMobileOrTab && 6,
                            }}>
                            {!isMobileOrTab
                              ? 'Roleplay the moves displayed below and guess the next best move'
                              : 'Select the exact chessboard square shown on your screen'}
                          </CCText>
                        </Box>
                        {isMobileOrTab && (
                          <Box
                            style={{
                              justifyContent: 'center',
                            }}>
                            <LevelInfoBox
                              isMobileOrTab={isMobileOrTab}
                              isUpgradeVisible={isUpgradeVisible}
                              level={level}
                              handlePressOnGetLevel={handlePressOnGetLevel}
                              getLevel={getLevel}
                              marginVertical={15}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                    {isMobileOrTab && (
                      <CCButton
                        style={{
                          // width: '90vw',
                          justifyContent: 'center',

                          marginTop: isMobileOrTab ? 0 : 20,
                        }}
                        onPress={() => {
                          setShowDropDown(false);
                          setStartTraining(true);
                        }}>
                        <CCText
                          fontSize={14}
                          style={{
                            paddingVertical: isMobileOrTab ? 2 : 5,
                          }}>
                          Start Training
                        </CCText>
                      </CCButton>
                    )}
                  </>
                )}
                {!isMobileOrTab && (
                  <>
                    <LevelInfoBox
                      isMobileOrTab={isMobileOrTab}
                      isUpgradeVisible={isUpgradeVisible}
                      level={level}
                      handlePressOnGetLevel={handlePressOnGetLevel}
                      getLevel={getLevel}
                      marginVertical={0}
                    />
                    <Box
                      style={{
                        flexDirection: 'column',
                        // alignItems: 'flex-start',
                        alignItems: 'center',
                        marginTop: isMobileOrTab ? 10 : 22,
                        gap: 5,
                        marginHorizontal: isMobileOrTab && '6%',
                      }}>
                      <CCText
                        fontSize={FontSizes[20]}
                        fontWeight={500}
                        color={CCColors.TextColor.Dark}
                        textAlign={'justify'}>
                        Calculation Trainer
                      </CCText>
                      <CCText
                        color={CCColors.TextColor.LightGrey}
                        fontSize={14}
                        fontWeight="regular"
                        textAlign="center"
                        style={{
                          width: '100%',
                          flexWrap: 'wrap',
                        }}>
                        {!isMobileOrTab
                          ? 'Roleplay the moves displayed below and guess the next best move'
                          : 'Select the exact chessboard square shown on your screen'}
                      </CCText>
                    </Box>

                    <Box
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center">
                      <CCText
                        textAlign="center"
                        fontSize={14}
                        style={
                          !isMobileOrTab
                            ? styles.puzzleId
                            : styles.puzzleIdMobile
                        }>
                        <CCText fontWeight="bold" fontSize={14}>
                          Puzzle id:
                        </CCText>{' '}
                        #{puzzleId}
                      </CCText>
                    </Box>
                    <Box justifyContent="center">
                      <TabView
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        navigationState={{index: index, routes}}
                        renderScene={() => {}}
                        renderTabBar={props => {
                          const {navigationState} = props;
                          const {index: activeIndex, routes} = navigationState;

                          return (
                            <TabBar
                              {...props}
                              indicatorStyle={{
                                height: '100%',
                                backgroundColor: CCColors.Primary.Brown,
                                borderTopLeftRadius:
                                  props.navigationState.index === 0 ? 8 : 0,
                                borderBottomLeftRadius:
                                  props.navigationState.index === 0 ? 8 : 0,
                                borderTopRightRadius:
                                  props.navigationState.index ===
                                  props.navigationState.routes.length - 1
                                    ? 8
                                    : 0, // Rounded for last tab
                                borderBottomRightRadius:
                                  props.navigationState.index ===
                                  props.navigationState.routes.length - 1
                                    ? 8
                                    : 0,
                              }}
                              contentContainerStyle={{height: 32}}
                              style={styles.boxTabBar}
                              tabStyle={{
                                borderRightWidth: 2,
                                borderRightColor: CCColors.BorderLight,
                              }}
                              renderLabel={({route, focused, ...rest}) => {
                                const totalItems =
                                  props?.navigationState?.routes?.length;
                                const itemId =
                                  props?.navigationState?.routes?.findIndex(
                                    i => i?.key === route?.key,
                                  );

                                return (
                                  <CCText
                                    fontWeight="semibold"
                                    fontSize={FontSizes[14]}
                                    style={[
                                      styles.boxTabBarLabel,
                                      focused ? styles.boxIndicator : {},
                                      {
                                        color: focused
                                          ? CCColors?.White
                                          : CCColors?.TextColor?.Primary,
                                      },
                                      itemId === 0
                                        ? styles.boxLeftBorder
                                        : itemId === totalItems - 1
                                        ? styles.boxRightBorder
                                        : null,
                                    ]}>
                                    {route.title}
                                  </CCText>
                                );
                              }}
                            />
                          );
                        }}
                        onIndexChange={setIndex}
                      />
                    </Box>
                  </>
                )}
              </>
            )}

            <Box
              marginTop={!isMobileOrTab && 20}
              style={{
                width: !isMobileOrTab && '100%',

                overflow: 'hidden',
              }}
              justifyContent="flex-end">
              <Box
                flexDirection="row"
                justifyContent="space-between"
                marginBottom={isMobileOrTab ? 12 : 6}
                marginLeft={!isMobileOrTab ? '10%' : '0%'}
                marginRight={!isMobileOrTab ? '10%' : '0%'}>
                {((isMobileOrTab && startTraining) || !isMobileOrTab) && (
                  <CCText
                    fontSize={14}
                    fontWeight="medium"
                    style={{marginLeft: isMobileOrTab && '6%', marginTop: 10}}>
                    Blind-fold moves
                  </CCText>
                )}
                {((isMobileOrTab && startTraining) || !isMobileOrTab) && (
                  <Box flexDirection="row" gap={4} alignItems="flex-end">
                    <Image
                      resizeMethod="resize"
                      source={
                        colorPiece === 'white'
                          ? require('@/Assets/Images/ChessPieces/White-Rook.png')
                          : require('@/Assets/Images/ChessPieces/Black-Rook.png')
                      }
                      alt="rook"
                      style={{width: 28, height: 24}}
                    />
                    <CCText fontWeight="regular" fontSize={14}>
                      {colorPiece[0].toUpperCase() +
                        colorPiece.slice(1, colorPiece.length)}{' '}
                      to play
                    </CCText>
                  </Box>
                )}
              </Box>
              {isMobileOrTab && <Spacer spacing={20} />}
              {!isMobileOrTab && <Spacer spacing={10} />}
              {((isMobileOrTab && startTraining) || !isMobileOrTab) &&
                (isNan ? (
                  <Box
                    style={{
                      marginVertical: 20,
                    }}>
                    <CCText
                      textAlign="center"
                      style={{
                        fontWeight: 500,
                        fontSize: FontSizes[18],
                      }}>
                      We don't have any new positions available for you. Please
                      play more games in gameplay to generate new positions.
                    </CCText>
                  </Box>
                ) : (
                  <ScrollView>
                    {sequenceArray.map((item, index, array) => {
                      if (index % 2 === 0) {
                        return (
                          <Box
                            flexDirection="row"
                            alignItems="center"
                            style={{width: '100%'}}>
                            <Box
                              style={{
                                width: !isMobileOrTab ? '10%' : '5%',
                                marginRight: 5,
                              }}>
                              <CCText
                                textAlign={isMobileOrTab ? 'left' : 'right'}
                                color={CCColors.TextColor.Grey}
                                fontSize={!isMobileOrTab ? 12 : 14}
                                fontWeight="regular">
                                {numberArray[index]}
                              </CCText>
                            </Box>
                            <Box
                              style={[
                                index === 0
                                  ? blindFoldStyles.eachMoveWrapper1
                                  : blindFoldStyles.eachMoveWrapper,
                                {
                                  width: !isMobileOrTab ? '40%' : '45%',
                                  paddingVertical: !isMobileOrTab ? 14 : 10,
                                },
                                index === sequenceArray.length - 1 &&
                                  isInputFocused && {
                                    borderColor: CCColors.Progress.Bar,
                                    borderBottomLeftRadius: 12,
                                  },
                              ]}>
                              <CCText fontSize={isMobileOrTab ? 14 : 16}>
                                {sequenceArray[index]}
                              </CCText>
                            </Box>
                            <Box
                              style={[
                                index === 0
                                  ? blindFoldStyles.eachMoveWrapper2
                                  : blindFoldStyles.eachMoveWrapper,
                                {
                                  width: !isMobileOrTab ? '40%' : '45%',
                                  paddingVertical: !isMobileOrTab ? 14 : 10,
                                },
                                index === sequenceArray.length - 1
                                  ? {
                                      borderWidth: 0,
                                      backgroundColor: 'inherit',
                                    }
                                  : {borderWidth: 1},
                                index === sequenceArray.length - 2 &&
                                  isInputFocused && {
                                    borderColor: CCColors.Progress.Bar,
                                    borderBottomRightRadius: 12,
                                  },
                              ]}>
                              <CCText fontSize={isMobileOrTab ? 14 : 16}>
                                {sequenceArray[index + 1]}
                              </CCText>
                            </Box>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ScrollView>
                ))}
              {/* <ConditionalRender
                condition={!verificationLoading && !isLoading}
                childrenA={
                  <CCText
                    style={{
                      marginLeft: !isMobileOrTab ? '12%' : '8%',
                      marginTop: 5,
                    }}
                    textAlign="left"
                    fontSize={!isMobileOrTab ? 14 : 12}
                    fontWeight="medium"
                    color={
                      correctAnswer === 'Correct!'
                        ? CCColors.Green
                        : CCColors.WrongMoves.Background
                    }>
                    {correctAnswer}
                  </CCText>
                }
              /> */}
              <ConditionalRender
                condition={verificationLoading}
                childrenA={
                  <CCText
                    style={{marginLeft: '12%', marginTop: 5}}
                    textAlign="left"
                    fontSize={14}
                    fontWeight="medium">
                    verifying...
                  </CCText>
                }
              />
              {((isMobileOrTab && startTraining) || !isMobileOrTab) && (
                <ConditionalRender
                  condition={correctAnswer !== 'Correct!'}
                  childrenA={
                    !isNan && (
                      <CCButton
                        disabled={isRecording || isConvertingToText}
                        type="outline"
                        style={{
                          backgroundColor: CCColors.Buttons.Background,
                          marginTop: 32,
                          marginLeft: !isMobileOrTab && '10%',
                          marginRight: !isMobileOrTab && '10%',
                        }}
                        onPress={() => {
                          verifyPuzzle({
                            puzzleId: puzzleId,
                            move: value,
                            moves_count: noOfMoves,
                          });
                        }}>
                        <CCText
                          fontSize={isMobileOrTab && 14}
                          style={{paddingVertical: isMobileOrTab && 5}}>
                          Verify
                        </CCText>
                      </CCButton>
                    )
                  }
                  childrenB={
                    <CCButton
                      type="outline"
                      style={{
                        backgroundColor: CCColors.Buttons.Background,
                        marginTop: 25,
                        marginLeft: '10%',
                        marginRight: '10%',
                      }}
                      onPress={() => {
                        setRerender(prev => prev + 1);
                        setCorrectAnswer('');
                      }}>
                      <CCText>Solve next</CCText>
                    </CCButton>
                  }
                />
              )}

              {false && !isMobileOrTab && (
                <>
                  <Divider
                    marginTop={25}
                    marginBottom={25}
                    style={{marginLeft: '10%', marginRight: '10%'}}
                  />
                  {!isRecording && !isConvertingToText && (
                    <Box
                      marginLeft="10%"
                      marginRight="10%"
                      marginBottom={10}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <CCText>Or Share your answer by recording</CCText>
                      <TouchableOpacity
                        style={{
                          backgroundColor: CCColors.Buttons.Background,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 10,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          borderRadius: 8,
                        }}
                        onPress={() => {
                          if (micPermission === 'granted') {
                            onStartRecordHandler();
                          } else {
                            handleMicPermission();
                          }
                        }}>
                        <ConditionalRender
                          condition={micPermission === 'granted'}
                          childrenA={
                            <>
                              <Image
                                resizeMethod="resize"
                                source={require('@/Assets/Images/png/record_button.png')}
                                alt="record button"
                                style={{width: 8, height: 8}}
                              />
                              <CCText>Record</CCText>
                            </>
                          }
                          childrenB={
                            <Tooltip
                              position={!isMobileOrTab ? 'left' : 'bottom'}
                              text={
                                <CCText
                                  color={CCColors.White}
                                  fontSize={14}
                                  fontWeight="regular">
                                  Please give mic access to start recording!
                                </CCText>
                              }
                              children={toggleEvent => {
                                return (
                                  <View
                                    onPointerEnter={toggleEvent}
                                    onPointerLeave={toggleEvent}>
                                    <Box
                                      flexDirection="row"
                                      alignItems="center"
                                      gap={10}>
                                      <Image
                                        resizeMethod="resize"
                                        source={require('@/Assets/Images/png/record_button.png')}
                                        alt="record button"
                                        style={{width: 8, height: 8}}
                                      />
                                      <CCText>Record</CCText>
                                    </Box>
                                  </View>
                                );
                              }}
                            />
                          }
                        />
                      </TouchableOpacity>
                    </Box>
                  )}
                  {isRecording && (
                    <>
                      <Box
                        marginLeft="10%"
                        marginRight="10%"
                        flexDirection="row"
                        justifyContent="space-between"
                        style={{
                          backgroundColor: CCColors.White,
                          borderRadius: 32,
                          padding: 5,
                        }}
                        alignItems="center">
                        <Box
                          flexDirection="row"
                          alignItems="center"
                          gap={10}
                          onPress={() => {}}>
                          <Image
                            resizeMethod="resize"
                            source={require('@/Assets/Images/png/record_button.png')}
                            alt="record button"
                            style={{width: 8, height: 8}}
                          />
                          <CCText>00:0{timer}</CCText>
                        </Box>
                        <Image
                          resizeMethod="resize"
                          source={require('@/Assets/Images/png/voice_process.png')}
                          alt="voice process"
                          style={{width: 202, height: 8}}
                        />
                        <Pressable onPress={onStopRecordHandler}>
                          <Image
                            resizeMethod="resize"
                            source={require('@/Assets/Images/png/stop_record.png')}
                            alt="stop record"
                            style={{width: 87, height: 28}}
                          />
                        </Pressable>
                      </Box>
                    </>
                  )}
                  {isConvertingToText && (
                    <Box
                      flexDirection="row"
                      gap={8}
                      marginLeft="10%"
                      marginRight="10%"
                      style={{
                        backgroundColor: CCColors.White,
                        borderRadius: 32,
                        paddingHorizontal: 12,
                        paddingVertical: 16,
                      }}>
                      <Image
                        resizeMethod="resize"
                        source={require('@/Assets/Images/png/refresh.png')}
                        alt="converting"
                        style={{width: 16, height: 16}}
                      />
                      <CCText fontSize={12} fontWeight="medium">
                        Converting audio into text ...
                      </CCText>
                    </Box>
                  )}
                </>
              )}

              {isMobileOrTab && startTraining && (
                <Box
                  margin={20}
                  style={{width: SCREEN_WIDTH}}
                  onPress={() => {
                    setIsVisible(true);
                    onStartRecordHandler();
                  }}
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Icon
                    name="mic"
                    size={30}
                    color={CCColors.Green}
                    style={{marginRight: '15%'}}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {isMobileOrTab && isVisible && (
            <BottomSheetCopy isVisible={isVisible} setIsVisible={setIsVisible}>
              <ConditionalRender
                condition={!state2Visible}
                childrenA={bottomSheetState1()}
                childrenB={bottomSheetState2()}
              />
            </BottomSheetCopy>
          )}
        </Box>
      </Box>

      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        user={dashboardData?.user}
        planMessage={planMessage}
        currentPlan={planName}
      />
    </ScrollView>
  );
};

export default React.memo(CalculationTrainer);
